import React, { useState } from "react";
import App from "./App";
import { Stack } from "@mui/material";
import "../src/pages/home/home.scss";

const LandingPage = (props) => {
  const [isCorporate, setIsCorporate] = useState(false);
  const [show, setShow] = useState(false);

  const handleClickCorp = () => {
    setIsCorporate(true);
    setShow(true);
  };

  const handleClickRet = () => {
    setIsCorporate(false);
    setShow(true);
  };

  return (  
  
    <div className="main"> 
      {!show ?
        <div>
          <Stack className="juxaroo" sx={{ justifyContent: "center", alignItems: "center", }}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{xs:5, sm:25}} >
              {console.log(window.location.pathname)}
              {console.log(window.location)}

              {window.location.pathname ==='/' ? handleClickRet() : window.location.pathname ==='/business'? handleClickCorp(): null}
              
                {/* <button className="btn1" onClick={handleClickRet} >Retail</button>
                <button className="btn1" onClick={handleClickCorp} >Corporate</button> */}
            </Stack>

          </Stack>
        </div>

      :
      
        <App flag={isCorporate} />
      }
      </div>
  
  );
};

export default LandingPage;
