import React, {useState, useRef, useEffect, useContext  } from 'react';
import { useNavigate} from "react-router-dom";
import { UserLogInContext } from '../../App'// import { Link } from "react-router-dom";
//import MainFrame from '../../components/MainFrame.jsx';         // import Sidebar from "../../components/Sidebar.jsx";// import Navbar from "../../components/Navbar.jsx";
import '../../resource/images/autom8-logo.png'
import Button from '@mui/material/Button';     //import Autocomplete from '@mui/material/Autocomplete';  
import {TextField, Stack, Typography, FormControl} from '@mui/material';  //import  from '@mui/material/Typography'; //import Box from '@mui/material/Box'; // import TextField from '@mui/material/TextField';
import "./profile.scss";
import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js';
import { useTheme , PhoneNumberField, Table, TableRow, TableBody, TableCell } from '@aws-amplify/ui-react';
import { mailformat, mobileNumber} from '../../components/Regex.js'; 
import { ModPrompt, dateConverter } from '../../util/Functions.jsx';
import styled from 'styled-components';
import ApplicationBar from '../../components/AppBar.jsx';
//import {PasswordInput} from '../../components/Styles.jsx'

const Container = styled.div`
    background: ${props => props.bgc};
    //color: ${props => props.primary ? "black" : "transparent"};
    margin-top:1ch;
    padding: 6ch 6ch;
    padding-top:2ch;
    //border: 3px solid ${props=> props.bc } ;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height:${props=>props.h};
    width:${props=>props.w};
    box-shadow:0 0 6px 4px ${props=>props.bc};
`;

const {
    REACT_APP_API_HTTP,
    REACT_APP_API_LOCAL_DOMAIN,
    REACT_APP_API_LOCAL_PORT,
    REACT_APP_USER_POOL_ID, 
    REACT_APP_CLIENT_ID, 
    //REACT_APP_DEBUG, 
    REACT_APP_AWS_REGION,
    NODE_ENV,REACT_APP_API_UPDATE_USER_DATA,
    REACT_APP_API_GET_USER_DATA_PROFILE
  } = process.env;
  
const productionHost = REACT_APP_API_HTTP + window.location.host;
const localHost = REACT_APP_API_HTTP + REACT_APP_API_LOCAL_DOMAIN + REACT_APP_API_LOCAL_PORT;
const DOMAIN = `${NODE_ENV==='production'? productionHost : localHost}`; 
//const debug = REACT_APP_DEBUG ==="on";

const poolData = {
    UserPoolId: REACT_APP_USER_POOL_ID, // Your user pool id here
    ClientId: REACT_APP_CLIENT_ID, // Your client id here
};

export const Profile = () => {

    const {user, group, subId, role, subrole, customerrole, userfullname, userId, isSuperUser, time}  = useContext(UserLogInContext);    //console.log(role, customerrole)    //let  {id}  = useParams();
    const navigate = useNavigate();
    const { tokens } = useTheme();    //const {authStatus} = useAuthenticator();
    const [userName, setUserName] = useState(user);
    const [userFullName, setUserFullName] = useState('');
    const [userSelectedType, setUserSelectedType] = useState('');
    const [id, setId]=useState();
    const [userEmail, setUserEmail] = useState('aiampolski@figure8services.com.au');
    //const [verification, setVerification] = useState(false);
    const [userSubType, setUserSubType] = useState('');
    const [active, setActive] = useState(false);
    //const [userType, setUserType] = useState([]);
    const [inputs, setInputs] = useState({});
    const [checked, setChecked] = useState(true);
    const [phoneNum, setPhoneNum] = useState('');  //0455230620
    const [dialCode, setDialCode]= useState('+61');    //const [confirm, setConfirm] = useState(false);    //const [open, setOpen] = useState(false);
    const [alertBox, setAlertBox] = useState(false);
    const [alertText, setAlertText] =useState('');
    const [alertTitle, setAlertTitle] =useState('');
    const [password, setPassword] = useState('');
    const [alertPassword, setAlertPassword] = useState('');
    
    //const statusPro = useRef(false)
    const inputRef = useRef(''); //    
    const dialCodeRef = useRef('+61');
    const confirm = useRef(false)
    const VALID = useRef(false);
    const VALID_fullName = useRef(false);
    const VALID_email = useRef(true);
    const VALID_active = useRef(false);    //    const VALID_businesParther = useRef(false);        //const countryCode = useRef(null)    //const debug = process.env.REACT_APP_DEBUG ==='on';    //const getUserTypes=()=>{setUserType(['Administration', 'Manager', 'Senior Technician','Field Technician', 'Contractor', 'Customer', 'Client'])};


    useEffect(()=>{
        ///getUserTypes()// setInputs(values=>({...values, ['phoneNumber']: ''}));        // setInputs(values=>({...values, ['business_partner']:''}))        //console.log(`${DOMAIN}${REACT_APP_API_GET_USER_DATA_PROFILE}${subId}`) ///user subID in Cognitor
        getUserData_From_DB(`${DOMAIN}${REACT_APP_API_GET_USER_DATA_PROFILE}${subId}`)        //setLoaded(true)  
    }, []);

    useEffect(()=>{
        console.log('use effect  ', alertBox)
        alertBox && CONFIRM()
        setAlertBox(false)
    },[alertPassword]);

    const validateField = (inputText, flag)=>{
        flag===''? VALID.current = true: 
            inputText.match(flag)? 
            VALID.current = true: 
            VALID.current = false
        return VALID.current;
    };

    const handleClose = ()=>{ navigate('/') };

    const handleUpdate = ()=>{
        // const invalidFields=[];

        // for (const key in inputs) {            //debug && console.log(`${key}: ${inputs[key]}`) ////to remove
        //     inputs[key]==="INVALID" && invalidFields.push(key)
        // }
        // if (invalidFields.length > 0){        //debug && console.log(invalidFields)  ////to remove//
        //     //setErrorFields(invalidFields)
        // }else{                                
        //console.log(`${DOMAIN}${REACT_APP_API_UPDATE_USER_DATA}${id}`, inputs)
        updateUser_In_DB(`${DOMAIN}${REACT_APP_API_UPDATE_USER_DATA}${id}`, inputs);
        //handleClose();
            
            //window.location.reload()      //
             // }
    };

    async function getUserData_From_DB(Url) {  
        const response = await fetch(Url);
        const responseData = await response.json();
        const data = responseData[0];          //console.log(data)

        const keys= Object.keys(data)
        keys.forEach(k=>{setInputs(values=>({...values, [k]: data[k]}))})

        setId(data.userId)
        //setUserName(user);
        setUserFullName(data.userFullName);
        setUserSelectedType(isSuperUser ? "SUPER USER" : data.userType);
        // setHashPassWord(data.passWord==='removed'||data.passWord==='Removed' || data.passWord ==''? '':''//verifyHashedPassword(data.passWord)                );
        setUserEmail(data.userEmail);
        setUserSubType(isSuperUser ? "SUPER USER" : data.userSubType);
        //setPhoneNum(data.phoneNumber); //.slice( data.phoneNumber.indexOf(" ")).trim())     ///{(data.phoneNumber ?? setDialCode('+61') )|| setDialCode(data.phoneNumber.slice(0, data.phoneNumber.indexOf(" ")).trim())}
        setActive(data.active ===1 ? true:false);
        setChecked(data.active===1 ? false:true)
        ///setSubAttrib(data.sub ===null || data.sub ==='null' || data.sub==='' ? "EMPTY": data.sub);

        if(data.phoneNumber == null || data.phoneNumber === 'null'|| data.phoneNumber===''){
            setDialCode('+61')
            setPhoneNum('');
            dialCodeRef.current = '+61'
        }else{
            //console.log(data.phoneNumber.slice(1, data.phoneNumber.indexOf(")")).trim() )
            //console.log(data.phoneNumber.slice(data.phoneNumber.indexOf(")")+1).trim())

            dialCodeRef.current = data.phoneNumber.slice(1, data.phoneNumber.indexOf(")")).trim()

            setDialCode(data.phoneNumber.slice(1, data.phoneNumber.indexOf(")")).trim());
            setPhoneNum(data.phoneNumber.slice(data.phoneNumber.indexOf(")")+1).trim());
        }
        VALID.current = true;
    
        //VALID_fullName.current = true;
        VALID_email.current = validateField(data.userEmail, mailformat);
        VALID_active.current = true;
        //VALID_businesParther.current=true;
    };

    async function updateUser_In_DB(Url, BodyInput) {   
        console.log(BodyInput) /// text/plain; */*; charset=utf-8
        let success =false;
        const requestOptions = {   
            method: 'PUT', 
            body: JSON.stringify({ BodyInput }),    
            headers: {'Content-Type':'application/json; charset=utf-8'}, 
        } 
        const response = await fetch(Url, requestOptions);
        console.log(response.status)
        const responseData = await response.json();  
        console.log(responseData.changedRows)
        console.log(responseData.affectedRows)
        console.log(responseData)
        //success=responseData.changedRows===1
        
        // if(success){
        //     // const a = window.confirm('\nProfile details have been changed successfuly. \nPlease log out and log back in again to see new changes')       // a && navigate('/');
        //     setVerification(true)
        // }else{
        //     setVerification(false)
        // }        
    };

    function promtpMe(){
        setAlertBox(true)
        setAlertText('Please enter your password')
        setAlertTitle('Password')
        console.log(password)
        //return (<ModPrompt description={alertText} isOpen= {alertBox} setIsOpen={setAlertBox} setDescription={setAlertText} setResponse ={setPassword}/>)
    }

///////////////////////////////

    const CONFIRM= async()=>{

        console.log('LLLLLLLLLLLLLLLLLLLLLLLLLLL')
        const userPool = new CognitoUserPool(poolData)
        const cognitoUser = new CognitoUser({
            Username: userName,  //testaccount
            Pool: userPool,
        });
    
        await authentUser(cognitoUser);             // if (flag==='confirm'){//const authenticationDetails = new AuthenticationDetails({Username : userName, Password : window.prompt("Please enter password","")})///"!Hello_11?"});   //authentUser();        //     //.deleteUser((a)=>{console.log(a)})); //console.log(user.getUsername());  //getUserSession();        // }else{        //     authentUser();        // }             
        confirm.current && await getUserSession(cognitoUser);
        confirm.current && await updateAttributes(cognitoUser);
        //await changePswd(userName)
        navigate('/')

    };
//////////////////////////////

    const authentUser = async (cognitoUser) => {    
        //let psw = prompt("Please enter password","")
        
        if (alertPassword?.length > 0){
            const authenticationDetails = new AuthenticationDetails( {
                                                Username : userName, 
                                                Password : alertPassword
                                                });     
            return new Promise((resolve, reject) => cognitoUser.authenticateUser( authenticationDetails, 
                {   onSuccess: result =>{ 
                        resolve(result);  //                          console.log(result, '  -----   success');
                        setInputs(values=>({...values, ['active']:1}))
                        confirm.current= true                           
                        return;
                    },
                    onFailure: err => {
                            reject(err)
                            const missUserName='Missing required parameter USERNAME'
                            const userNotConfirm='User is not confirmed'
                            const incorectNamePsw ='Incorrect username or password'
                            setInputs(values=>({...values, ['active']:0}))                                
                            if (err.message.includes(missUserName)){
                                alert( "You have not supplied the user name for confirmation" )
                            }else if(err.message.includes(userNotConfirm)){
                                alert( "User is not confirmed. Validation link has been sent to the user. User has not used the supplied lik to validate their account yet" )
                            }else if(err.message.includes(incorectNamePsw)){
                                alert( "You have entered incorrect password" )
                            }else{
                                alert( "Something went wrong, please try again later" )
                            }
                            confirm.current= false
                            return;            //alert(`Password you entered is different \nto the password we have for this user. This could either mean: \n\n1. You entered wrong password\n2. Password you entered is an original password \nand it assumed that user logged in to Autom8 \nand changed password successfuly`)
                    }      } 
                
            ));
        } else{
            console.log('hhhhhhhh')
            handleClose()    
        }       
    }; 

    // const changePswd =(userName)=>{

    //     const userPool = new CognitoUserPool(poolData)
    //     const cognitouser = new CognitoUser({
    //         Username: userName,  //testaccount
    //         Pool: userPool,
    //     });

    //     const newUserPassword ='!Rachel_11?';
    //     const oldUserPassword  ='Figure8_services' ;

    //     return new Promise((resolve, reject) =>
    //         cognitouser.changePassword( oldUserPassword, newUserPassword,  (err, result)=>{
    //             if (err) {
    //                 alert( err.message || JSON.stringify(err));
    //                 reject(err.message || JSON.stringify(err));                    
    //                 return false;
    //             }
    //             resolve(result) // -- returns  CognitoUserSession                +
    //             console.log(result)
    //             return true;

    //         },
    //         )
    //     )
    // } ;  

    const getUserSession = (cognitoUser) => {

        return new Promise((resolve, reject) =>
            cognitoUser.getSession( (err, result)=>{
                if (err) {
                    alert( err.message || JSON.stringify(err));
                    reject(err.message || JSON.stringify(err));
                    confirm.current= false
                    return;
                }
                resolve(result) // -- returns  CognitoUserSession                //console.log(result)
                setInputs(values=>({...values, ['acive']: 1}))                 //statusPro.current = true;                
                confirm.current= true
            },
        
        ));    
    } 

    const updateAttributes = (cognitoUser)=>{
        console.log(cognitoUser)

        const attributeList = [];
        // const attributeRole = new CognitoUserAttribute({
        //     Name: 'custom:Role',
        //     Value: userSelectedType,
        // });

        const attributeName = new CognitoUserAttribute( {
            Name: 'name',   //custom:CustomerRole
            Value: userFullName,
        });
        
        //attributeList.push(attributeRole);  //console.log(attributeRole.getValue(), attributeName.getValue())
        attributeList.push(attributeName);

        return new Promise((resolve, reject) =>
        
            cognitoUser.updateAttributes( attributeList, function(err, result) {
                if (err) {
                    reject(err.message || JSON.stringify(err));
                    alert( err.message || JSON.stringify(err));
                    return;
                }
                resolve(result) // if success returnes "SUCCESS"
                handleUpdate()
            })
        )    
    } 

////////////
    const handleOnDialCodeChange = (e)=> {
        // setPhoneNum('');
        // setDialCode(e.target.value )
        e.preventDefault();
        //setPhoneNum('');
        dialCodeRef.current= e.target.value
        setDialCode(dialCodeRef.current);
        console.log(e.target.value)
        console.log(dialCodeRef.current)
        console.log(`${dialCodeRef.current} ${phoneNum}` )  
        
        setInputs(values=>({...values, ['phoneNumber']: `(${e.target.value.trim()}) ${phoneNum}`}))   
    };

    const onChangePhone=(e) =>{ 
        // console.log(dialCodeRef.current.value, phoneNum)
        //setPhoneNum(e.target.value.trim().replaceAll(' ','')); //setInputs(values=>({...values, ['phoneNumber']: `${dialCodeRef.current.value} ${e.target.value}`}))

        console.log(dialCode )  
        //dialCodeRef.current.value="+10"
        setInputs(values=>({...values, ['phoneNumber']: `(${dialCode}) ${e.target.value}`})) 
        console.log(`(${dialCode}) ${e.target.value}`)   
        console.log(dialCodeRef.current.value )          //(e.target.value.replaceAll(' ',''))
        console.log(inputRef.current.value)
        setPhoneNum(`${e.target.value}`); 
    };
    
    const theme = {
        name: 'phonenumber-theme',
        tokens: {
          components: {
            phonenumberfield: {
              //these design tokens will apply to both the select and input that are part of the phonenumber field
              color: { value: '{colors.blue.60}' },
              fontSize: { value: '{fontSizes.small}' },
              fontFamily: { value: '{fontFamily.cursive}' },
              borderColor: { value: '{colors.green.60}' },
              _focus: {orderColor: { value: '{colors.neutral.100}' },
              },
            },
          },
        },
    };

    return (
        < >
            <ApplicationBar/>
        
            <Stack className="profileScreen" >                                   
                <Stack className='itemTable'>  

                    <Stack boxShadow= '0 0 1px 1px gray'  sx={{ borderRadius:'5px'}}>

                        <Table
                            caption={
                                <Stack border='1px solid lightgray' paddingY={3}>
                                    <Typography color='#2d187be0' fontSize={16} fontWeight={600} >Profile Details</Typography>
                                </Stack>
                            }
                            size="small"
                            highlightOnHover={true}
                            variation="striped"
                            backgroundColor={'antiquewhite'}
                            width='100ch'
                        >
                            <TableBody> 

                                <TableRow>
                                        
                                        <TableCell border='1px solid lightgray' ><Typography  style={{ color:'#2d187be0', fontWeight:600}}>Full Name </Typography></TableCell>

                                        <TableCell border='1px solid lightgray' >  
                                            <FormControl fullWidth	sx={{fontWeight:600}}>
                                            
                                                <TextField   
                                                    value={userFullName} 
                                                    autoFocus={true}
                                                    required
                                                    size='small'  
                                                    fullWidth 
                                                                                                                                                                                                                                                          
                                                    //style={{fontWeight:600, color:'#2d187be0', width:'40ch', backgroundColor:'azure'} }                                   
                                                    onChange={(e)=>{
                                                        setUserFullName(e.target.value)
                                                        setInputs(values=>({...values, ['userFullName']:e.target.value}  ))
                                                    }}
                                                /> 
                                            </FormControl>  
                                                                  
                                        </TableCell>                                                                                       
                                </TableRow>

                                <TableRow>
                                    
                                    <TableCell border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontWeight:600, }}>Phone</Typography></TableCell>
                                    <TableCell border='1px solid lightgray'>
                                        <Typography style={{fontWeight:400, }}>
                                            <PhoneNumberField
                                                defaultDialCode={'+61'}
                                                dialCodeList={['+1','+7', '+10', '+55', '+44', '+61', '+64']}
                                                ref={inputRef}
                                                dialCodeRef={dialCodeRef}
                                                //borderwidth={tokens.borderWidths.large}
                                                value={`${phoneNum.trim() === '' ?'' :phoneNum.trim()}`}      //placeholder="Phone number" //border={`1px solid ${tokens.colors.green[60]}`}
                                                labelHidden                                                    //dialCodeLabel="Dial code"                                             //phonenumberfield ="1111"
                                                width={'42.8ch'}
                                                backgroundColor={'azure'}
                                                type='tel'
                                                hasError={phoneNum.trim() !==''? phoneNum.trim().match(mobileNumber) !== null? false:true:true}
                                                errorMessage="Not a valid phone number! 😱"
                                                onChange={onChangePhone}    ///{e=>setPhoneNum(e.target.value)}                     //onBlur={onBlur}             //onCountryCodeChange={handleOnCountryCodeChange}
                                                onDialCodeChange={handleOnDialCodeChange}
                                            />
                                        </Typography>

                                    </TableCell>                                              
                                </TableRow>
                            
                                <TableRow height='5ch'>
                                    
                                    <TableCell border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontWeight:600, }}> Loggin ID</Typography></TableCell>
                                    <TableCell border='1px solid lightgray'><Typography style={{ fontWeight:400, }}>{userName}</Typography></TableCell>                                                
                                </TableRow>

                                <TableRow  height='5ch'>
                                    
                                    <TableCell border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontWeight:600, }}>Cognito ID</Typography></TableCell>
                                    <TableCell border='1px solid lightgray'><Typography style={{fontWeight:400, }}> {subId}</Typography></TableCell>
                                    
                                </TableRow>

                                <TableRow  height='5ch'>
                                    
                                    <TableCell border='1px solid lightgray'><Typography style={{color:'#2d187be0',  fontWeight:600, }}>A8 User ID </Typography></TableCell>
                                    <TableCell border='1px solid lightgray'><Typography style={{  fontWeight:400, }}>{inputs.userId}</Typography></TableCell>                                                
                                </TableRow>

                                <TableRow >
                                    
                                    <TableCell border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontWeight:600, }}>Type </Typography></TableCell>
                                    <TableCell border='1px solid lightgray'><Typography style={{ fontWeight:400, }}>{userSelectedType}</Typography> </TableCell>                                                
                                </TableRow>

                                <TableRow>
                                    
                                    <TableCell border='1px solid lightgray'>
                                        <Typography style={{color:'#2d187be0', fontWeight:600, }}>
                                            {role.toUpperCase() ==='Customer'.toUpperCase()  || role.toUpperCase()  ==='Client'.toUpperCase()  || role.toUpperCase() ==='Manager'.toUpperCase()  ? 
                                                    userSubType.indexOf(',') !== -1 ? 
                                                    'Managing Customers' :
                                                    'Managing Customer '
                                                : role.toUpperCase() ==='Senior Technician'.toUpperCase() ? userSubType.indexOf(',') !== -1 ?'Managing States':'Managing State'
                                                : role.toUpperCase() ==='Field Technician'.toUpperCase() ? 'Managing State'
                                                : role.toUpperCase() ==='Contractor'.toUpperCase() ? "Contrasctor's Entity": 'Role'
                                            } 
                                        </Typography>
                                    </TableCell>

                                    <TableCell border='1px solid lightgray'>
                                        <Typography style={{  fontWeight:400, }}>
                                            {userSubType.replaceAll(',', ",  ")}
                                        </Typography> 
                                    </TableCell>                                                
                                </TableRow>

                                <TableRow  height='5ch'>                                        
                                    <TableCell border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontWeight:600, }}>Logging in Time </Typography></TableCell>
                                    <TableCell border='1px solid lightgray'><Typography>{dateConverter(time)}</Typography></TableCell>                                                
                                </TableRow>
                            </TableBody > 
                            
                        </Table>

                    </Stack>

                    <Stack name= 'BUTTONS' direction="row" marginTop={'4ch'} gap={6} >
                        <Button 
                            className='Button1'
                            sx={{ fontSize:11, borderRadius:'5px'}} 
                            variant='outlined'
                            onClick={promtpMe} //CONFIRM()                                                                
                        ><Typography fontWeight={600} fontSize={12}> Update </Typography></Button>

                        <Button 
                            className='Button1' 
                            sx={{fontSize:11, borderRadius:'5px'}} 
                            variant='outlined'
                            onClick={handleClose}                                                // sx={{ fontWeight:600, fontSize:14, fontFamily:'cursive', borderRadius:'5px'}}
                        ><Typography fontWeight={600} fontSize={12}>Close</Typography> </Button>
                    </Stack>
                
                </Stack>                                                         
            </Stack>   

            <ModPrompt isOpen= {alertBox} description={alertText} setResponse={setAlertPassword} isPassword={true}/>               
        </>
    
    )
};


     {/* <Stack className='newItemP'> </Stack>   */}
    {/* <Container bc="lightgray" w='100ch' h='77ch' bgc=''>  */}
    {/* <ModPrompt description={alertText} isOpen= {alertBox} setIsOpen={setAlertBox} setDescription={setAlertText} setResponse ={setPassword}/> */}