import React, {useState} from 'react';
import "./list.scss";
import MainFrame from '../../components/MainFrame.jsx';// import Sidebar from "../../components/Sidebar.jsx";// import Navbar from "../../components/Navbar.jsx";
import EnhancedTable from '../../components/JobGrid.jsx'
import ApplicationBar from '../../components/AppBar.jsx';

export const List = ({type}) => {

  const [message, setMessage]=useState('');
  const [modalResponce, setModalResponse] =useState();  // console.log(type) // console.log(message) //setMessage('hr')
 
  return (
      //MainFrame tableName = {type} message={message} setMessage={setMessage} setModalResponse={setModalResponse}> <div className="list">     //   <Sidebar />   //<div className="listContainer">   //     <Navbar tableName = {type} message={message} setMessage={setMessage} setModalResponse={setModalResponse}/>

    <>
      <ApplicationBar tableName = {type} setMessage={setMessage}  modalResponce={modalResponce}  setModalResponse={setModalResponse}/>
      <div className="tableContainer">
        <EnhancedTable tableName = {type} message={message} setMessage={setMessage} modalResponce={modalResponce} setModalResponse={setModalResponse}/>
      </div>
    </>

    )
};

List.defaultProps = {type:'jobbook'}



// type === "jobs"?'jobbook':
// type ==="clients"?"clients":
// type ==="products"?"products":
// type ==="customers"?"customers":
// type ==="users"?"users":
// type ==="orders"?"orders":
// ""}



//   let data;
//   switch (type) {
//     case "clients":
//       data = {
//         title: "CLIENTS",
//         link: "See all clients",
//       };
//       break;
//     case "customers":
//       data = {
//         title: "CUSTOMERS",
//         link: "See all customers",
//       };
//       break;

//     case "orders":
//       data = {
//         title: "ORDERS",
//         link: "View all orders",
        
//       };
//       break;
//     case "products":
//       data = {
//         title: "PRODUCTS",
//         link: "test",
        
//       };
//       break;
//     case "jobs":
//       data = {
//         title: "JOBS",
//         link: "See jobs",
//       };
//       break;
//      case'default' :
//       data={
//         title: "DEFAULT",
//         link: "test details",
//       };
//       break;
//     default:
//       break;
//   }


