import React, { useState, useEffect, useRef, useContext } from "react";
import { UserLogInContext } from "../App"; //import {Context} from '../../src/components/Navbar.jsx';//import {UserLogInContext} from '../App'
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableFooter from '@mui/material/TableFooter';
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow"; // import Checkbox from '@mui/material/Checkbox';     // import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Stack,
  Typography,
  Fab,
  Box,
  Toolbar,
  Paper,
  TableSortLabel,
  Tooltip,
  Zoom,
  Fade,
} from "@mui/material"; //  Fade, Card, CardContent, CardActions, Button, import IconButton from '@mui/material/IconButton';  // import Switch from '@mui/material/Switch';
import { tooltipClasses } from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import visuallyHidden from "@mui/utils/visuallyHidden"; //import "./newtable.scss";
import "./jobgrid.scss";
import a8 from "../resource/images/F8.png";
import { Link, useNavigate } from "react-router-dom";
import { setHeader } from "./jobGridTableName"; // import { useNavigate} from "react-router-dom";//import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";// import {  FormControl, Grid, TextField } from '@mui/material';   // import { autoShowTooltip } from 'aws-amplify';//import { sizing } from '@mui/system';//import { FormatAlignJustify } from '@mui/icons-material';//import { blue } from '@mui/material/colors';
import { SwitchField, useTheme } from "@aws-amplify/ui-react";
import { dateConverter, ModPrompt, useWindowDimensions } from "../../src/util/Functions";
import { DialogueAlert, DialogueResponce } from "../components/DialogueAlert"; //import picT from '../resource/images/Toshiba.png' //import picF from "../resource/images/Fred.png";
import Accordion from "@mui/material/Accordion"; //import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails"; //import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"; //import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { ContactPageSharp } from "@mui/icons-material";

const {
  REACT_APP_API_HTTP,
  REACT_APP_API_LOCAL_DOMAIN,
  REACT_APP_API_LOCAL_PORT,
  NODE_ENV,
  REACT_APP_API_GET_CUSTOMER_DATA,
  REACT_APP_API_GET_CLIENT_DATA,
  REACT_APP_API_GET_CONTRACTOR_DATA,
  REACT_APP_API_GET_SITE_DATA,
  REACT_APP_DEBUG,
} = process.env;

const productionHost = REACT_APP_API_HTTP + window.location.host;
const localHost =
  REACT_APP_API_HTTP + REACT_APP_API_LOCAL_DOMAIN + REACT_APP_API_LOCAL_PORT;
const DOMAIN = NODE_ENV === "production" ? productionHost : localHost;

const DEBUG = REACT_APP_DEBUG; //let selectedOption;   //let isSelectedOption=false
let headCells = []; // let tableID =''

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    placement="top"
    TransitionComponent={Zoom}
    followCursor
    arrow
  />
))({ [`& .${tooltipClasses.tooltip}`]: { minWidth: 300 } });

function descendingComparator(a, b, orderBy) {
  // console.log("a => ", a )  // console.log("B => ", b  )  // console.log("OOORRDDDER +>" , orderBy )
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={i}
            style={{ top: 2, minWidth: 15 }}
            //align="left" //{headCell.numeric ? 'right' : 'left'} padding={headCell.disablePadding ? 'none' : 'normal'}            //  padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "desc"} ////    sx={{ fontSize:'12px', fontWeight:'400'}}   ///////////////change direction
              onClick={createSortHandler(headCell.id)} //onClick ={(event)=>onRequestSort(event, headCell.id)}
            >
              <Typography fontSize={{xs:10, sm:12, md:15}} fontWeight={600} color="steelblue">
                {headCell.label}
              </Typography>

              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  //numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired, //  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, tableName, description, role } = props; // DEBUG && console.log(numSelected, tableName + "---" + description)
  let nSelect = numSelected ?? 0;

  return (
    <Toolbar
      sx={{
        pt: 0,
        pl: { sm: 0 },
        pr: { xs: 1, sm: 1},
        ...(nSelect > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        className="gradient-text"
        sx={{ flex: '1' }}        //variant="h5"        //id="tableTitle"        //component="div"
        fontWeight={600}
        marginLeft={{sm:"5px", md:"40px"}}
        fontSize={{sm:16, md:24}}
      >
        {tableName.toUpperCase() === "contractors".toUpperCase()
          ? "Service Providers List"
          : tableName.toUpperCase() === "jobparts".toUpperCase()
          ? "Parts List"
          : tableName.toUpperCase() === "jobbook".toUpperCase()
          ? "Jobs List"
          : tableName.charAt(0).toUpperCase() + tableName.slice(1) + " List"}
      </Typography>

      {role !== "Contractor" && role !== "Field Technician" && (
        <Link to="new">
          <Fab
            variant="extended"            //size="small"
            color="primary"          //  width={50}
            sx={{ mr: 4, zIndex: 0}}
            size='small'
        
          >
            <AddIcon sx={{ mr: 0 }} />
            <Typography sx={{display: { xs: "none", md: "flex" } }} fontWeight={600} fontSize={15}> Add </Typography>
          </Fab>
        </Link>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = { numSelected: PropTypes.number };


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

////////////!!!!!!!!!!!!!!!!!!! message ////////////////////////////////////////////////////////////////////////

export default function EnhancedTable({
  tableName,
  message,
  setMessage,
  modalResponce,
  setModalResponse,
}) {
  const { user, role, subrole, isSuperUser } = useContext(UserLogInContext); //  DEBUG && console.log(subrole, role, user)
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([0]);
  const [description, setDescription] = useState("");
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [active, setActive] = useState(false); //const [sort, setSort] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [rows, setRows] = useState([]);
  const [fontSize, setFontSize] = useState(14); // const [fontFamily, setFontFamily] = useState('Ariel');
  const [subRoles, setSubRoles] = useState(subrole.split(","));
  const [isOpen, setIsOpen] = useState(false);
  const [modDescription, setModDescription] = useState(""); //const[modTitle, setModTitle] =useState('');
  const [modResponse, setModResponse] = useState("");
  const [toolTipValue, setToolTipValue] = useState("");
  const [toolTipText, setToolTipText] = useState(); // ( <Typography> //   <p>{`1. Part Name: \f`}</p> //<p><br/></p> //<p>{`2. Part ID `}</p> //<p><br/></p> //   <p>{`3. pfdhhhhh  `}</p>  // <p><br/></p> // <p>{`4. kjj `}</p> // </Typography>);

  const resp = useRef(""); //const isLoadedFirstTime = useRef(true)    //let msg= (message == undefined ? false : message ===''?false : message)
  const { tokens } = useTheme();
  headCells = setHeader(tableName);

  useEffect(() => {
    console.log(" KKKKKK =========  " + modalResponce);
    const url = `${DOMAIN}${
      process.env.REACT_APP_API_URL_GET_ALL
    }${tableName}/${
      message === undefined ? false : message === "" ? false : message
    }/${modalResponce}/${!active}/${role}`; //==undefined ? false : modalResponce ==='' ? false : modalResponce
    getData(url); // fired when looaded list with search details       // message!== undefined ? message==''?false:message:false}`);
    //setModalResponse(undefined)
    setSelected(0); //  message!== undefined &&  setMessage(tableName)
    setPage(0);
  }, [message, tableName, active]); //message!== undefined && message])  getData(`${DOMAIN}${process.env.REACT_APP_API_URL_GET_ALL}${tableName}/${false}`);

  useEffect(() => {

    
    //  const url  =`${DOMAIN}${process.env.REACT_APP_API_URL_GET_ALL}${tableName}/${false}/${'false'}/${active}/${role}`;
    // active  &&  getData(url); // fired when loaded the list
    //  (tableName =='clients' || tableName =='customers' || tableName =='users' ) && setMessage('');
  }, [tableName, active]);

  useEffect(() => {
    if (resp.current) {
      const tn = tableName;
      const rout =
        tn === "customers"
          ? REACT_APP_API_GET_CUSTOMER_DATA
          : tn === "clients"
          ? REACT_APP_API_GET_CLIENT_DATA
          : tn === "sites"
          ? REACT_APP_API_GET_SITE_DATA
          : REACT_APP_API_GET_CONTRACTOR_DATA;
      deleteData(`${DOMAIN}${rout}delete/${selected}/a`);
      navigate("/");
    }
    resp.current = modResponse;
    setModResponse("");
    setIsOpen(false);
  }, [isOpen, modResponse]);

  useEffect(() => {
    toolTipText !== undefined && getInventoryPart(toolTipText);
  }, [toolTipText]);

  const getInventoryPart = async (id) => {
    const response = await fetch(`${DOMAIN}${"/api/stockpartsdata/"}${id}`);

    if (response.status == 200) {
      const partsData = await response.json();
      console.log(partsData); //setPartInStockDetails(responseData)//  \f

      if (partsData.length !== 0) {
        if (partsData[0]?.jobPartIsSerialized == "Yes") {
          setToolTipValue(
            partsData.map(
              (p) => (
                // <AccordionDetails>
                //  {/* <Stack direction={'row'} border={'1px dotted blue'}> defaultExpanded={false} */}

                <Accordion sx={{ width: "60ch" }}>
                  <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                    <Stack direction={"row"} gap={2}>
                      <Typography fontSize={12} fontWeight={500}>
            
                        {`Part Serial Number:`}
                      </Typography>
                      <Typography fontSize={12}>
              
                        {p.partSerialNumber}
                      </Typography>
                    </Stack>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Stack direction="row" gap={2}>
                      <Typography fontSize={12} fontWeight={500}>
            
                        {`Part Asset Number:`}
                      </Typography>
                      <Typography fontSize={12}>
                        {p.partsAssetNumber}
                      </Typography>
                      <Typography fontSize={12} fontWeight={500}>
                    
                        {`Location:`}
                      </Typography>
                      <Typography fontSize={12}>{p.locationName}</Typography>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              )

              //{/* <Stack sx={{fontSize:12, width: '50%', flexShrink: 0 }}> {p.partSerialNumber}</Stack>  <Stack sx={{fontSize:12, width: '50%', flexShrink: 0 }} >{p.partsAssetNumber}</Stack> */}

              //{/* </Stack> */}
              // </AccordionDetails>
            )
          );
        } else {
          setToolTipValue(
            partsData.map((p) => (
              <Accordion sx={{ width: "60ch" }}>
                <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                  <Stack direction={"row"} gap={2}>
                    <Typography>{p.locationName}</Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="row" gap={5}>
                    <Typography fontSize={12} fontWeight={500}>
                      {`Quantity:`}
                    </Typography>
                    <Typography fontSize={12}>{p.partQty}</Typography>
                  </Stack>
                  {/* <Stack sx={{fontSize:12, fontFamily:{fontFamily}, width: '50%', flexShrink: 0 }}> {p.locationName}</Stack>                                         
                      <Stack sx={{fontSize:12, fontFamily:{fontFamily}, width: '50%', flexShrink: 0 }} > {p.partQty}</Stack> */}
                </AccordionDetails>
              </Accordion>
            ))
          );
        }
      } else {
        setToolTipValue("");
      } //<AccordionDetails>Out of stock </AccordionDetails>   )
    } else {
      DEBUG &&
        console.log(
          `ERROR : status ${
            response.status
          } returned for ${"/api/stockpartsdata/"}${id}`
        );
    }
  };

  const test = (panel) => (e, isAccordionOpen) => {
    console.log(panel);
    console.log(e);
    console.log(isAccordionOpen);
  };

  //const { innerWidth: width, innerHeight: height } = window;
  const { height, width, size:sixe } = useWindowDimensions();

  function accordion(partName) {
    return (
      <Stack sx={{ height: "5ch", mt: -2 }}>
        <Accordion
          defaultExpanded={false}
          //expanded={expanded}
          slots={{ transition: Fade }}
          slotProps={{ transition: { timeout: 3400, unmountOnExit: true } }} // unmountOnExit: true  prop to improve performance:
          // sx={{
          //   '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
          //   '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' }
          // }}
          sx={{
            width: "90ch",
            height: "5.5ch",
            background: "none",
            display: "block",
          }}
          onChange={test("panel2")}
        >
          <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
            <Stack direction={"row"} gap={2}>
              {/* <Typography fontSize={12} fontWeight={500}></Typography>   */}
              <Typography fontSize={12} sx={{ ml: -2 }}>
                {partName}
              </Typography>
            </Stack>
          </AccordionSummary>

          <AccordionDetails>
            <Stack direction={"column"}>
              {/* <Stack sx={{fontSize:12, fontFamily:{fontFamily}, fontWeight:600, width: '50%', flexShrink: 0 }}> {qty==0 ? 'Out of Stock' : isSerialized ==1 ? `Serial Number`:'Location' }</Stack>                                         
                  <Stack sx={{fontSize:12, fontFamily:{fontFamily}, fontWeight:600, width: '50%', flexShrink: 0 }} > {qty==0 ? '' : isSerialized ==1 ? `Assets Number` : 'Quantity'}</Stack> */}
              <Typography>{toolTipValue}</Typography>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Stack>

      // {/*
      //   <Accordion defaultExpanded={false}>
      //     <AccordionSummary  expandIcon={<ArrowDownwardIcon  />}  >
      //       Assets Numbers
      //     </AccordionSummary>

      //     <AccordionDetails>
      //       Lorem ipsum dolor sit amet
      //     </AccordionDetails>
      //   </Accordion>

      //   <Accordion defaultExpanded = {false}>
      //     <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
      //             </AccordionSummary>

      //     <AccordionDetails>

      //     <Accordion defaultExpanded={false}>
      //       <AccordionSummary  expandIcon={<ArrowDownwardIcon  />}  >
      //       </AccordionSummary>

      //       <AccordionDetails>
      //       </AccordionDetails>
      //   </Accordion>

      //     </AccordionDetails>

      //     <AccordionActions>
      //       <Button onClick={()=>console.log('CLICKED')}>Cancel</Button>
      //       <Button>Agree</Button>
      //     </AccordionActions>

      //   </Accordion>
      //*/}
    );
  }

  const setTextForTip = (partId) => {
    console.log(partId);
    setToolTipText(
      <Typography>
        <p>{`1. Part Name: ${partId}\f`}</p>
        <p>
          <br />
        </p>
        <p>{`2. Part ID ${partId}`}</p>
        <p>
          <br />
        </p>
        <p>{`3. pfdhhhhh  `}</p>
        <p>
          <br />
        </p>
        <p>{`4. kjj `}</p>
      </Typography>
    );
  };

  async function getData(url) {
    DEBUG && console.log(url);
    const response = await fetch(url); //,{method: 'POST', headers: {'Content-Type': 'application/json; text/plain; */*; charset=utf-8'}, body: ''  });
    const responseData = await response.json();
    DEBUG && console.log(responseData);
    isSuperUser ? setRows(responseData) : selectJobs(responseData); //setRows(responseData)
  }

  const selectJobs = (jobs) => {
    let countRoles = 0;
    let countJobs = 0;
    const tempJob = [];

    jobs.forEach((job, i) => {
      //console.log('JOBS COUNTER', countJobs++)

      subRoles.forEach((sRole, i) => {
        ///console.log("ROLE COUNTER", countRoles++)

        switch (role.toUpperCase()) {
          case "Senior Technician".toUpperCase():
            //  DEBUG && console.log('S Techn here');
            if (sRole.toUpperCase() == job.siteState.toUpperCase()) {
              tempJob.push(job);
            }
            break;

          case "Administration".toUpperCase():
            //  DEBUG && console.log('Admin here');
            tempJob.push(job);
            break;

          case "Contractor".toUpperCase():
            DEBUG && console.log("Contractor " + subrole);
            job.supplierId == subrole && tempJob.push(job);
            break;

          case "Field Technician".toUpperCase():
            DEBUG && console.log("Contractor " + sRole);
            job.supplierId == Number(sRole) && tempJob.push(job);
            break;

          case "Customer".toUpperCase():
            DEBUG && console.log("Customer " + sRole);
            if (
              sRole.trim().toUpperCase() ==
              job.customerName.trim().toUpperCase()
            ) {
              tempJob.push(job);
            }
            break;

          case "Manager".toUpperCase():
            //  DEBUG && console.log('Customer ' + sRole);
            if (
              sRole.trim().toUpperCase() ==
              job.customerName.trim().toUpperCase()
            ) {
              tempJob.push(job);
            }
            break;

          case "Client".toUpperCase():
            DEBUG && console.log("Client " + sRole);
            if (
              sRole.trim().toUpperCase() ==
              job.customerName.trim().toUpperCase()
            ) {
              tempJob.push(job);
            }
            break;
        }
      });
    });

    setRows(tempJob);
    //DEBUG && console.log(tempJob)
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const promptMe = (tn) => {
    setModDescription(
      `Please confirm re-activation of this ${
        tn === "customers"
          ? "Customer"
          : tn === "clients"
          ? "Client"
          : tn === "users"
          ? "User"
          : tn === "sites"
          ? "Site"
          : "Service Provider"
      }`
    );
    setModResponse("");
    setIsOpen(true);
  };

  const handleClick = (e, id, name, status) => {
    DEBUG && console.log(status, id, name, e); // DEBUG && console.log(e)      // DEBUG && console.log(tableName)

    status === 0 && promptMe(tableName);
    //const tn = tableName
    setSelected(id);
    setDescription(name);
    //selectedOption=id.toString()

    if (status === 0 && tableName !== "users") {
      DEBUG && console.log(isOpen, resp.current); //  let a = prompt(`\n\nYou are about to REACTIVATE this ${tn==='customers'?'Customer': tn==="clients"?'Client':tn ==='users'?'user':tn ==='sites'?'Site':'Contractor'}   \nIf You wish to proceed type 'yes' then click OK\n`,'NO')
      DEBUG && console.log(resp.current);

      // if (resp.current===null){resp.current ='No'}
      // if (resp.current.toLowerCase()==='yes'){
      //   DEBUG && console.log("YESSSSSSSSS")

      //   // const rout = tn === 'customers' ? REACT_APP_API_GET_CUSTOMER_DATA:
      //   //              tn === 'clients' ? REACT_APP_API_GET_CLIENT_DATA:
      //   //              //tn === 'users' ? REACT_APP_API_GET_USER_DATA:
      //   //              tn === 'sites' ? REACT_APP_API_GET_SITE_DATA: REACT_APP_API_GET_CONTRACTOR_DATA;
      //   // deleteData(`${DOMAIN}${rout}delete/${id}/a`)
      //   // navigate(`/`)
      // }
    } else {
      navigate(`update/${id}`);
    } //newSelected.map(a=>a!==selectedOption? a= false:"")  //isSelectedOption=event.target.checked   //settngSelected(name, event.target.checked)
  };

  async function deleteData(a) {
    DEBUG && DEBUG && console.log(a);
    const response = await fetch(a, { method: "DELETE" });
    //const responseData = await response.json();
    //DEBUG && console.log(responseData.ok)  `${DOMAIN}/api/${type}/delete/${id}`
  } // const settngSelected =(s, isChecked)=>{  //   selectedOption=s.toString()  //   isSelectedOption=isChecked}

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
    event.target.checked ? setFontSize(12) : setFontSize(16);


  };

  const handleChangeActive = (e) => {
    setActive(e.target.checked);
  };

  const isSelected = (Id) => selected.indexOf(Id) !== -1; // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0; //{/* <ModPrompt description={descrip} isOpen={modalOpen} setIsOpen={setModalOpen} setResponse={setModeResp}/> */}
  return (
    <Paper
      sx={{
        width: "100%",
        height :'100%',
        px: .5,
        py: 0,
        overflow: "auto",
        //border: "1px solid lightgray",
      }}
    >

      <DialogueResponce
        isOpen={isOpen}     // {/* <ModPrompt isOpen={isOpen} description={modDescription} setResponse={setModResponse} isPassword={false} />    */}
        setIsOpen={setIsOpen}
        setValue={setModResponse}
        title={"Re-Activation Warning"}
        dialogueTxt={modDescription}
      />

      <EnhancedTableToolbar
        numSelected={0}
        tableName={tableName}
        description={description}
        role={role} //sx={{ height:'6ch' , border:'1px solid red'}}
      />

      <TableContainer
        sx={{
          maxHeight: height/1.27,  
          maxWidth: {xs:width/1.1, sm:width},
          border:'1px solid gray',
          p:0,
          ml:{xs:1.1,sm:0},
          overflow: "auto" 
        }}
        className="back1"
      >
        {/* {console.log(window.innerHeight)} {console.log(window.outerHeight)  } */}  {/* `You are about to Reactivate this ${tableName==='customers'?'customer': tableName==="clients"?'client':tableName ==='users'?'user':tableName ==='sites'?'site':'contractor'}! If You want to proceed type 'yes' then click OK\n` */}
        <Table
          stickyHeader //sx={{ overflow: "auto" }} //className='back1' //aria-labelledby="tableTitle"
          size={dense ? "small" : sixe}
        >                
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy} //onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length} //className='back1'
          />
          <TableBody>
            {rows
              .sort(getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                // (filterArr(row))     {group[0] ==='Admin' || role==='Administration'|| role=='Manager' ?
                //   const isItemSelected = isSelected(
                //     tableName==="jobbook"?row.jobNo:
                //     tableName==="clients"?row.clientId:
                //     tableName==="users"?row.userId:
                //     tableName==="customers"?row.customerId:
                //     tableName==="sites"?row.siteId:
                //     tableName==="contractors"?row.contractorid:
                //     "");
                //const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover                    
                    onContextMenu={(e) => {e.preventDefault()}} //console.log("Right Click", e); // onAuxClick={(e)=> console.log(e)}
                    onMouseLeave={() => setToolTipText(null)}
                    onDoubleClick={(e) => handleClick(e,
                        tableName === "jobbook"
                          ? row.jobNo
                          : tableName === "clients"
                          ? row.clientId
                          : tableName === "users"
                          ? row.userId
                          : tableName === "customers"
                          ? row.customerId
                          : tableName === "sites"
                          ? row.siteId
                          : tableName === "contractors"
                          ? row.supplierId
                          : tableName === "jobparts"
                          ? row.partsId
                          : tableName === "warehouses"
                          ? row.warehouseId
                          : "",
                        tableName === "jobbook"
                          ? row.jobNo
                          : tableName === "clients"
                          ? row.clientName
                          : tableName === "users"
                          ? row.name
                          : tableName === "customers"
                          ? row.customerName
                          : tableName === "sites"
                          ? row.siteName
                          : tableName === "contractors"
                          ? row.supplier
                          : tableName === "jobparts"
                          ? row.jobPartsDescription
                          : tableName === "warehouses"
                          ? row.warehouseName
                          : "",
                        row.active === undefined ? -1 : row.active
                      )
                    } //role="checkbox"      //aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index} // Math.round(Math.random() * 10000) + index} // tableName==="jobbook"?row.jobNo : tableName==="clients"?row.clientId: tableName==="users"?row.userId: tableName==="sites"?row.siteId:tableName==="customers"?row.customerId: Math.round(Math.random() * 10000)
                    sx={{backgroundColor:(row.active === 0 || row.active === "") && "pink",}} //selected = {isItemSelected}
                  >
                    {tableName === "clients" ? (
                      <>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:12, md:13}} overflow={'auto'}> {row.clientId}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:12, md:13}} overflow={'auto'}>{row.clientName} </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography width={{xs:'14ch', sm:'16ch', md:'37ch'}} fontSize={{xs:10, sm:12, md:13}} overflow={'auto'}> {row.clientEmail}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:12, md:13}} overflow={'auto'}>{row.clientABN}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography width={{xs:'10ch', sm:'12ch', md:'27ch'}}  fontSize={{xs:10, sm:12, md:13}} overflow={'auto'}>{row.clientPhoneNumber} </Typography>
                        </TableCell>
                      </>
                    ) : tableName === "sites" ? (
                      <>
                        {/* <Checkbox checked={isItemSelected}/> <TableCell>{row.siteId}</TableCell> */}
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>{row.siteName}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>
                            {row.siteAddress1}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>{row.siteState}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>{row.siteZone}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>
                            {row.customerName}
                          </Typography>
                        </TableCell>
                        {/* <TableCell sx={{ fontFamily:fontFamily, fontSize:fontSize}}>{`lat: ${row.position.x} long:${row.position.y}`}</TableCell> */}
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>{row.sitePhone}</Typography>
                        </TableCell>
                        {/* <TableCell sx={{ fontFamily:fontFamily, fontSize:fontSize}}>{row.siteZone ?? 0.0}</TableCell>        <TableCell sx={{fontFamily:fontFamily, fontSize:fontSize}}>{row.active ===0 ? <Badge variation="warning">Disable</Badge>: <Badge variation="success">Active</Badge>}</TableCell>  */}
                      </>
                    ) : tableName === "customers" ? (
                      <>
                        <TableCell align="left">
                          <Typography fontSize={{xs:10, sm:12, md:13}} >  
                            {row.customerId}
                          </Typography>
                        </TableCell>

                        {/* <Badge size="small"><NotificationsNoneOutlinedIcon/></Badge> */}
                        <TableCell align="left">
                          <Typography width={{xs:'12ch', sm: '26ch', }} fontSize={{xs:10, sm:12, md:13}} overflow={'auto'}>
                            {row.customerName}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography width={{xs:'12ch', sm: '16ch', }} fontSize={{xs:10, sm:12, md:13}} overflow={'auto'}>
                            {row.clientName}
                          </Typography>
                        </TableCell>

                        <TableCell >
                          <img
                            className={
                              dense ? "img1" : "img2"//row.customerName == "Toshiba" ? "imgWide" : "img2"
                            }
                            src={
                              row.customerLogo == "" || row.customerLogo == null
                                ? a8
                                : row.customerLogo
                            } //  =='toshiba' ? // picT           // : row.customerLogo =='Fred'? picF:row.customerLogo
                            //alt={<Typography fontSize={13}> row.customerName</Typography>}
                          />
                        </TableCell>

                        <TableCell >
                          <Typography width={{xs:'15ch', md:'37ch'}} fontSize={{xs:10, sm:12, md:13}} overflow={'auto'}>{row.customerEmail}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography  fontSize={{xs:10, sm:12, md:13}} overflow={'auto'}>{row.customerCCUUID}</Typography>
                        </TableCell>
                        {/* <TableCell sx={{fontSize:f.fs}}>{row.clientId}</TableCell> */}
                      </>
                    ) : tableName === "users" ? (
                      <>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>{row.userId}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>{row.name}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>
                            {row.userFullName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>{row.userEmail}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>{row.sub}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>{row.userType}</Typography>
                        </TableCell>
                      </>
                    ) : tableName === "jobbook" ? (
                      <>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}> {row.jobNo}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}> {row.customerName} </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>{row.siteName}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}}> {row.siteState} </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}}> {row.status}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>
                            {row.priority === 1
                              ? "Extreme"
                              : row.priority === 2
                              ? "Urgent"
                              : row.priority === 3
                              ? "Moderate"
                              : row.priority === 4
                              ? "Standard"
                              : row.priority === 5
                              ? "Low"
                              : ""}{" "}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}> {row.requestType} </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>{row.supplier}</Typography>
                        </TableCell>
                        {/* <TableCell sx={{ ontSize:f.fs}}>{dateConverter(row.dateCreated)}</TableCell> */}
                        <TableCell sx={{  color: Date.parse(new Date()) > row.slaDate * 1000 ? "red" : "", }} >
                          <Typography fontSize={{xs:10, sm:11, md:13}}> {dateConverter(row.slaDate)} </Typography>
                        </TableCell>
                      </>
                    ) : tableName === "warehouses" ? (
                      <>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>
                          
                            {row.warehouseId}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>
                        
                            {row.warehouseName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>
                          
                            {row.warehouseDescription}
                          </Typography>
                        </TableCell>
                      </>
                    ) : tableName === "contractors" ? (
                      <>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>
                            {row.supplierId}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>{row.supplier}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>
                            {row.supplierEmail}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>
                            {row.supplierState}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>
                            {row.supplierPhone}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>
                            {row.supplierContactPerson}
                          </Typography>
                        </TableCell>
                      </>
                    ) : tableName === "jobparts" ? (
                      <>
                        <CustomWidthTooltip
                          title={
                            row.Qty == 0
                              ? "OUT OF STOCK"
                              : `Total number ${row.Qty}`
                          }
                        >
                          <TableCell
                            onMouseEnter={() => {
                              setToolTipText(row.partsId);
                            }} //onMouseLeave={()=>setToolTipText(null)} sx={{ width:'1%'}}          // {toolTipText == row.partsId ? accordion() :
                          >
                            <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>{row.partsId}</Typography>
                          </TableCell>
                        </CustomWidthTooltip>
                        <CustomWidthTooltip
                          title={
                            row.Qty == 0
                              ? "OUT OF STOCK"
                              : `Parts in stock ${row.Qty}`
                          }
                        >
                          <TableCell
                            sx={{ width: "110ch" }}
                            onContextMenu={(e) => {
                              e.preventDefault();
                              setToolTipText(row.partsId);
                            }} // onMouseEnter={()=>setToolTipText( row.partsId)}///onMouseLeave={()=>setToolTipText(null )}
                          >
                            <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>
                              {toolTipText == row.partsId ? row.Qty > 0 ? accordion(row.jobPartsDescription) : row.jobPartsDescription : row.jobPartsDescription}
                            </Typography>
                            {/* {`${row.jobPartsDescription}`} */}
                          </TableCell>
                        </CustomWidthTooltip>
                        <CustomWidthTooltip
                          title={
                            row.Qty == 0
                              ? "OUT OF STOCK"
                              : `Parts in stock ${row.Qty}`
                          }
                        >
                          <TableCell //onMouseLeave={()=>setToolTipText(null )}
                            onContextMenu={(e) => {
                              e.preventDefault();
                              setToolTipText(row.partsId);
                            }}
                          >
                            <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>
                              {row.jobPartNumber}
                            </Typography>
                          </TableCell>
                        </CustomWidthTooltip>
                        <CustomWidthTooltip
                          title={
                            row.Qty == 0
                              ? "OUT OF STOCK"
                              : `Parts in stock ${row.Qty}`
                          }
                        >
                          <TableCell //onMouseLeave={()=>setToolTipText(null )}
                            onContextMenu={(e) => {
                              e.preventDefault();
                              setToolTipText(row.partsId);
                            }}
                          >
                            <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}> {row.prodCCUUID} </Typography>
                          </TableCell>
                        </CustomWidthTooltip>
                        <CustomWidthTooltip
                          title={
                            row.Qty == 0
                              ? "OUT OF STOCK"
                              : `Parts in stock ${row.Qty}`
                          }
                        >
                          <TableCell
                            onContextMenu={(e) => {
                              e.preventDefault();
                              setToolTipText(row.partsId);
                            }} //onMouseLeave={()=>setToolTipText(null )}
                          >
                            <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>
                              {row.customerName}
                            </Typography>
                          </TableCell>
                        </CustomWidthTooltip>
                        <CustomWidthTooltip
                          title={
                            row.Qty == 0
                              ? "OUT OF STOCK"
                              : `Parts in stock ${row.Qty}`
                          }
                        >
                          <TableCell //onMouseLeave={()=>setToolTipText(null )}
                            onContextMenu={(e) => {
                              e.preventDefault();
                              setToolTipText(row.partsId);
                            }}
                          >
                            <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'}>{row.Qty}</Typography>
                          </TableCell>
                        </CustomWidthTooltip>
                      </>
                    ) : null}
                  </TableRow>
                );
              })}

            {/* {emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 53) * (emptyRows !== 0 ? emptyRows : 2), }} >
              
                <TableCell colSpan={4} />
              </TableRow>
            )} */}
          </TableBody>

          <TableFooter>
            <TableRow>
              .
            </TableRow>
          </TableFooter>
          
        </Table>
      </TableContainer>

      <Stack  NAME='FOOTER' direction="row" display='flex' backgroundColor='white' sx={{p:.1,}} >

        <Stack direction="column" width="18ch" spacing={0} sx={{alignItems:"flex-start", ml:1}} justifyContent={tableName !== "jobbook"? "flex-start":'center'}> 

          <Stack sx={{ alignItems:"flex-start", }}  >  
            <SwitchField            
              label={ !dense ? ( <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'} fontFamily={"Arial"}> Minimise Table </Typography> ) :
                  (<Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'} fontFamily={"Arial"}>Maximize Table</Typography>)   }
              size="small"
              labelPosition="end"
              thumbColor={tokens.colors.orange[20]}
              isChecked={dense}
              onChange={handleChangeDense}
            />
          </Stack>  
    
          <Stack display={tableName !== "jobbook"? 'flex':'none'} >
            <SwitchField
              thumbColor={tokens.colors.orange[20]}
              label={active ? 
                <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'} fontFamily={"Arial"}>Show Active Only</Typography> : 
                <Typography fontSize={{xs:10, sm:11, md:13}} overflow={'auto'} fontFamily={"Arial"}>Show All</Typography> }    /// isChecked = { active? true : false} //size="small"
              labelPosition="end"
              size="small"
              onChange={handleChangeActive}
            />
          </Stack>
          
        </Stack>

        <Stack >
            <TablePagination 
              //fontSize={{xs:10, sm:11, md:13}}         //  sx={{fontSize:{xs:11, md:13}}}            //  fontFamily={"Arial"}
              //size='small'
              rowsPerPageOptions={[5, 12, 20, 30, 40,  { label: 'All', value: -1 }]}
              colSpan={3}
              //component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              slotProps={{
                select: {
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                },
              }}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
        </Stack>  

      </Stack>

  
    </Paper>
  );
}

////////////////////////////////////
// <Card variant="outlined" sx={{width: 400 }}>
//    <CardContent>
//       <Typography fontSize={14} fontFamily={'cursive'} sx={{ mx: '2px', transform: 'scale(.9)' }}>
//             <div><h3>Part:</h3> {partsData[0]?.jobPartsDescription } </div>
//             <div><br></br></div>
//             <div><h3>Stock ID:</h3>{partsData[0]?.stockpartsId }</div>
//             <div><br></br></div>
//             <div><h3>Stock Details:</h3> {partsData.map(p => <div> {p.partSerialNumber}<br /> </div>  )}  </div>
//             <div><br></br></div>
//             <div><h3>Location Name:</h3> {partsData[0]?.locationName ?? ''}  </div>

//       </Typography>
//   </CardContent>
// </Card>

// <Typography fontSize={12} fontFamily={'cursive'}>
//       <p>{`2. Part ID:  ${partsData[0]?.stockpartsId ?? ''}`}</p>
//       <p><br/></p>
//       <p>{`3. Location Name: ${partsData[0]?.locationName ?? ''}   `}</p>
//       <p><br/></p>
//       <p>{`4. kjj `}</p>
// </Typography>

///////////////////////////////////////////////////

// eslint-disable-next-line

// {/* {nSelect > 0 ? (
//   // <Typography
//   //   className='icon'
//   //   sx={{ flex: '1 1 100%', fontSize:18, fontWeight:700}}
//   //   // color="inherit"
//   //   // variant="subtitle1"
//   //   component="div"
//   // >
//   //   {/* {tableName==="jobbook"?'Job ID # ' :
//   //   tableName==="clients"?'Client ID # ':
//   //   tableName==="users"?'user ID # ':
//   //   tableName==="sites"?'Site ID # ':
//   //   tableName==="customers"?'Customer ID # ':''}
//   //   {numSelected}  */}
//   //   {`${description} is selected`}
//   // </Typography>

//   //<Fab sx={{flex: '1 1 100%', }} variant="extended">
//   <Typography
//       className='gradient-text'
//       sx={{ flex: '1 1 100%'}}
//       //id="tableTitle"
//       component="div"
//       variant="h5"
//       fontWeight={500}
//       fontFamily='cursive'
//       fontSize={32}
//       marginLeft='70px'
//   >
//     {`${tableName.charAt(0).toUpperCase() + tableName.slice(1,tableName==='jobbook'?-4:-1) } "${description}" is selected`}
//   </Typography>
//   //</Fab>
// ) : ( */}

// {numSelected[0] > 0 ? (
//   <Stack direction="row" width ='450px' spacing={5} >
//     <Tooltip
//       title={"Update " + tableName.charAt(0).toUpperCase() + tableName.slice(1,-1) }
//     >
//         <Link
//           to= {{pathname: `update/${selectedOption}`}}
//           style={{textDecoration: "none" }}
//         >
//           <Fab
//             variant="extended"
//             size="medium"
//             color="success"
//             aria-label="edit"
//             sx={{mr:'10px', mt:'2px', mr:'75px', fontFamily:'cursive',fontSize:18}}
//           >
//             <EditIcon />edit
//           </Fab>
//         </Link>
//     </Tooltip>

//     <Tooltip
//       title={"Delete " + tableName.charAt(0).toUpperCase() + tableName.slice(1,-1)}
//     >
//       <Link
//         to= {{pathname: `delete/${selectedOption}`}}
//         style={{ textDecoration: "none" }}
//       >
//         <Fab
//           variant="extended"
//           size='medium'
//           color="secondary" aria-label="delete"
//           sx={{mt:'2px', mr:'75px', fontFamily:'cursive',fontSize:18 }}
//         >
//             <DeleteOutlineIcon />delete
//         </Fab>
//       </Link>
//     </Tooltip>
// </Stack>
//   //////////////////////////////////========//sx={{ flex: '1 1 100%' }}
// ) : (

// {/* <Grid
//   container
//   direction='column'
//   alignItems='center'
//   justifyContent='center'
// >
//     <Autocomplete
//         value={''}
//         //required
//         //isOptionEqualToValue={(option, value) => option.value === value.value}
//         //  filterOptions={'filterOptions'}
//         style={{ width: "60%",  marginRight:'15ch' }}
//         name='searchField'
//         size='small'
//         //disableClearable={false}
//         disabled={false}
//         //autoHighlight  //loading = {true}
//         options={[1,2,3,4,5,6,7]}
//         onChange={(event, newValue) => {
//           {DEBUG && console.log(newValue)} //siteSelected.siteName.map((val, index)=>val === newValue &&  setInputs(values=>({...values, ['siteId']:siteSelected.siteId[index] })));
//         }}
//         renderInput={(params) => (
//             <TextField  label='Select...' {...params}
//               inputProps = {{...params.inputProps, type: 'search', }}
//               InputLabelProps={{ ...params.InputLabelProps}}
//             />
//           )
//         }
//     />
//   </Grid> */}

//const selectedIndex = selected.indexOf(name);
// DEBUG && console.log(selectedIndex)
// let newSelected = [];
// if (selectedIndex === -1) {
//   newSelected = newSelected.concat(selected, name);
// } else if (selectedIndex === 0) {
//   newSelected = newSelected.concat(selected.slice(1));
// } else if (selectedIndex === selected.length - 1) {
//   newSelected = newSelected.concat(selected.slice(0, -1));
// } else if (selectedIndex > 0) {
//   newSelected = newSelected.concat(
//   selected.slice(0, selectedIndex),
//   selected.slice(selectedIndex + 1),);
// }
// if (selfSelectedCheck !== id ){
//   newSelected=[]
//   newSelected.push(id)
//   setSelfSelectedCheck(id)
// }else{
//   newSelected=[]
//   setSelfSelectedCheck('')
// }

// const dateConverter = (sladate)=>{
//   const date = new Date(sladate *1000)// convert from 10 numbers to 13 numbers

//   let options = {
//       //weekday: "short",
//       day:"numeric",
//       month:"numeric",
//       year:"2-digit",
//       hour:'numeric',
//       //era: 'narrow' ,  //"long",
//       //dayPeriod:"long",
//       //dayPeriod: "short",
//       minute: "numeric",
//       //second: "numeric",
//       timeZone: "Australia/Sydney",
//       //hour12: false,
//       //timeZoneName: "short",
//   };

//   // let b = new Date(1662445426 * 1000)
//   // let now = new Date()
//   // let now1 = new Date()
//   // DEBUG && console.log(now1)
//   // let date1 = now.getDate() + '-' + parseInt(now.getMonth() + 1) + '-' + now.getFullYear() + ' ' + now.getHours() + ':' + now.getMinutes()
//   //     SETTING up SLA
//   // let ab = new Intl.DateTimeFormat('en-AU', options).format((now.setHours(now.getHours() + 5) ));
//   // const b1 = new Intl.DateTimeFormat('en-default', options).format((now1.setHours(now1.getHours() + 5) ));

//     //DEBUG && console.log(date.toLocaleString('en-AU', options))//'UTC' }));  { timeZone: 'Australia/Sydney'}
//   return date.toLocaleString('en-AU', options)
//   //return new Intl.DateTimeFormat('en-AU', options).format(date)///date.toString()  ar-EG
// };
