import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  Button,
  TextField,
  Switch,
  MenuItem,
} from "@mui/material"; // Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Autocomplete
import "./newitem.scss";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import NewContact from "./NewContact"; //import { Menu, MenuButton, MenuItem } from '@aws-amplify/ui-react';
import styled from "styled-components";
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  SelectField,
  Icon,
} from "@aws-amplify/ui-react";
import {
  mailformat,
  companyABN,
  phonenum,
  BSBformat,
  Accountformat,
  notEmpty,
} from "../Regex.js";
import { DialogueResponce } from "../../components/DialogueAlert.jsx";
import { SearchAddress } from "../../util/Functions.jsx";

const Container = styled.div`
  //background: ${(props) => props.bgc};
  //color: ${(props) => (props.primary ? "black" : "transparent")};
  margin-top: 0ch;
  padding: 1ch 4ch;
  ///padding-top:5ch;
  //border: 3px solid ${(props) => props.bc} ;
  border-radius: 5px;
  // display: flex;
  // flex-direction: column;
  height: ${(props) => props.h};
  width: ${(props) => props.w};
  //box-shadow:0px 0px 3px 2px ${(props) => props.bc};
`;

const NewCustomer = ({ action }) => {
  //const [customerName, setCustomerName] = useState('');     //const [clientID, setClientID] = useState('');    //const [clientName, setClientName] = useState([{name:'', id:null,}]);
  const [selectedClientName, setSelectedClientName] = useState("");
  const [clients, setClients] = useState([]); //const [customerLogo, setCustomerLogo] = useState('');    //const [customerEmail, setCustomerEmail] = useState('');
  const [active, setActive] = useState(1);
  const [isCorporate, setIsCorporate] = useState(false);
  const [inputs, setInputs] = useState({});
  const [open, setOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState(false);
  const [customerAddress, setCustomerAddress] = useState("");
  const [clientSuburb, setClientSuburb] = useState("");
  const [customerState, setCustomerState] = useState("");
  const [clientPostCode, setClientPostCode] = useState("");
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });

  const windowWidth = useRef("");
  const lat = useRef(0);
  const lng = useRef(0);

  const VALID_Name = useRef(false);
  const VALID_email = useRef(false);
  const VALID_client = useRef(false);
  const VALID_businessName = useRef(false);
  const VALID_registeredBisinessName = useRef(false);
  const VALID_abn = useRef(false);
  const VALID_PhoneNumber = useRef(false); //"clientPhoneNumber"
  const VALID_BSB = useRef(false);
  const VALID_AccountNumber = useRef(false);

  let { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  ////////////////
  const RETAILCLIENTNAME = "Retail Customers"; // get from the DB
  const RETAILCLIENTID = 65; // get from the DB find the better solution for la
  //////////////
  const productionHost = process.env.REACT_APP_API_HTTP + window.location.host;
  const localHost =
    process.env.REACT_APP_API_HTTP +
    process.env.REACT_APP_API_LOCAL_DOMAIN +
    process.env.REACT_APP_API_LOCAL_PORT;
  const DOMAIN = `${
    process.env.NODE_ENV === "production" ? productionHost : localHost
  }`;
  const DEBUG = process.env.REACT_APP_DEBUG; ///&& console.log(DOMAIN) /////////////  //const loading = true;

  const getClientList = async () => {
    const response = await fetch(
      `${DOMAIN}${process.env.REACT_APP_API_GET_CLIENTS_LIST}`
    );
    const responseData = await response.json(); //setClientID(responseData.map(d=>d.clientId))   //setClientName(responseData.map(d=>d.clientName))
    //setClientName(values=>({...values, ['name']: responseData.map(d=>d.clientName), ['id']: responseData.map(d=>d.clientId)}))
    //console.log(responseData)
    setClients(responseData);

    // setClients([
    //   "Select Client ...", 
    //   ...responseData.map((c) => c.clientId !== RETAILCLIENTID && c.clientName   ),
    // ])
  };

  function getWindowSize() {
    const { innerWidth, innerHeight, outerWidth } = window; // let zoom = (outerWidth / innerWidth).toFixed(2) // DEBUG && console.log('zoom: ', parseFloat(zoom)) // DEBUG && console.log(outerWidth, innerWidth)
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    getClientList();
    if (action === "update") {
      getCustomerData(
        `${DOMAIN}${process.env.REACT_APP_API_GET_CUSTOMER_DATA}${id}`
      );
    } else {
      setInputs((values) => ({ ...values, ["customerName"]: "" })); //         setInputs(values=>({...values, ['clientId']: ''}))
      setInputs((values) => ({ ...values, ["customerEmail"]: "" }));
      setInputs((values) => ({ ...values, ["customerLogo"]: "" }));
      setInputs((values) => ({ ...values, ["corporate"]: false }));
      setInputs((values) => ({
        ...values,
        ["clientId"]: parseInt(RETAILCLIENTID),
      }));
    }
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize()); //setZoom()
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    windowWidth.current = (
      windowSize.innerWidth /
      (windowSize.innerWidth / 670)
    ).toString();
  }, [windowSize]);

  useEffect(() => {
    if (dialogValue) {
      deleteData(
        `${DOMAIN}${process.env.REACT_APP_API_GET_CUSTOMER_DATA}delete/${id}/d`
      ); /// `http://localhost:3002/api/${type}/delete/${id}`
      navigate("/customers");
    }
    setDialogValue(false);
    setOpen(false);
  }, [dialogValue]);

  useEffect(() => {
    setInputs((values) => ({
      ...values,
      ["customerAddress"]: customerAddress,
    }));

    setInputs((values) => ({
      ...values,
      ["lat"]: lat.current,
    }));

    setInputs((values) => ({
      ...values,
      ["lng"]: lng.current,
    }));
  }, [customerAddress]);

  // const handleSubmit = (e) => {
  //     e.preventDefault();
  //     if (dialogValue.toLowerCase() === 'yes'){
  //         deleteData(`${DOMAIN}${process.env.REACT_APP_API_GET_CUSTOMER_DATA}delete/${id}/d`) /// `http://localhost:3002/api/${type}/delete/${id}`
  //         navigate(-1)
  //     }
  //     handleCloseDialog();
  // };

  const IconArrowDropDown = () => {
    return <Icon pathData="M7 10L12 15L17 10H7Z" />;
  };
  const openDialog = () => {
    setTimeout(() => {
      setOpen(true);
    });
  };

  //getting data for update
  const getCustomerData = async (Url) => {
    const response = await fetch(Url);
    if (response.status !== 400) {
      const responseCustomersData = await response.json();

      //console.log(response.statusText);
      const customer = responseCustomersData[0];
      console.log(customer);

      const keys = Object.keys(customer);
      keys.forEach((key) => {
        setInputs((values) => ({ ...values, [key]: customer[key] }));
      });

      setSelectedClientName(customer.clientName);
      setActive(customer.active);
      setIsCorporate(customer.corporate == 1 ? true : false);
      setCustomerAddress(customer.customerAddress);
      VALID_BSB.current = validateField(customer.customerBSB, BSBformat);
      VALID_AccountNumber.current = validateField(
        customer.customerAccountNumber,
        Accountformat
      );
      VALID_Name.current = customer.customerName === "" ? true : false;

      // keys.forEach((k) => {
      //   if (k == "customerName") {
      //     if (customer[k].length > 0) {
      //       VALID_Name.current = true;
      //     } else {
      //       VALID_Name.current = false;
      //     }
      //   }
      // });
      keys.forEach((k) => {
        if (k == "customerEmail") {
          if (customer[k].length > 0) {
            VALID_email.current = true;
          } else {
            VALID_email.current = false;
          }
        }
      });
    } else {
      console.log(
        "Error has occured while processing your request " + response.statusText
      );
    }
  };

  const handleClose = () => {
    navigate(-1);
  };

  const handleAdd = () => {
    DEBUG && console.log(inputs); // getClientList()
    postNewUsertData(
      DOMAIN + process.env.REACT_APP_API_ADD_NEW_CUSTOMER,
      inputs
    );
    navigate(-1);
  };

  const handleUpdate = () => {
    DEBUG && console.log(inputs);
    putCusomerData(
      `${DOMAIN}${process.env.REACT_APP_API_UPDATE_CUSTOMER_DATA}${id}`,
      inputs
    );
    navigate(-1);
  };

  const putCusomerData = async (Url, BodyInput) => {
    //console.log(BodyInput) /// text/plain; */*; charset=utf-8
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify({ BodyInput }),
      headers: { "Content-Type": "application/json; charset=utf-8" },
    };
    const response = await fetch(Url, requestOptions);
    if (response.status == 200) {
      const responseData = await response.json();
    }
  };

  const postNewUsertData = async (Url, BodyInput) => {
    //console.log(BodyInput) /// text/plain; */*; charset=utf-8
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({ BodyInput }),
    };
    const response = await fetch(Url, requestOptions);
    const responseData = await response.json();
    DEBUG && console.log(responseData);
  };

  const validateField = (textToTest, regExp) => {
    return regExp === "" ? true : textToTest?.match(regExp) ? true : false;
  };

  // const handleChange=(e, validated)=>{
  //     const names= e.target.name
  //     const value = e.target.value
  //     setInputs(values=>({...values, [names]: value, isValidated: validated,}));  //value, valid:
  // }

  async function deleteData(a) {
    DEBUG && console.log(a);
    const response = await fetch(a, { method: "DELETE" });
    const responseData = await response.json();
  }

  const ReActivate = () => {
    let a = prompt(
      `\n\nWARNING: You are about to Re-activate this Customers! \nIf You wish to proceed type 'yes' and then click OK button\n`,
      "NO"
    );
    if (a.toLowerCase() === "yes") {
      DEBUG && console.log("INNNN");
      //console.log(`${DOMAIN}${process.env.REACT_APP_API_GET_CUSTOMER_DATA}delete/${id}`)
      deleteData(
        `${DOMAIN}${process.env.REACT_APP_API_GET_CUSTOMER_DATA}delete/${id}/a`
      ); /// `http://localhost:3002/api/${type}/delete/${id}`
      navigate(-1);
    }
  };

  return (
    <Box
      component="form"
      sx={{ "& .MuiTextField-root": { ml: 0, width: "100%" } }}
      
      autoComplete="off"
    >
    
      <Stack width={{sm:'45ch', xl: '70ch'}}>
        <Stack sx={{ boxShadow: "1px 1px 2px 1px lightgray" }} >

          <Table
            caption={
              <Stack
                direction="row"
                sx={{
                  // flex: "1 1 100%",
                  border: "1px solid lightsteelblue",
                  paddingY: 1,
                  backgroundColor: "lightsteelblue",
                }}
                justifyContent="center"
                gap={2}
              >
                <Typography color={"InfoText"} fontWeight={600}>
                  {" "}
                  {action == "update"
                    ? "Updating Customer:  "
                    : "Adding Customer: "}
                </Typography>
                <Typography color={"red"} fontWeight={600}>
                  {" "}
                  {inputs.customerName}{" "}
                </Typography>
              </Stack>
            }
            size="small"
            highlightOnHover={true}
            variation="bordered" //border='1px solid lightgray'
            //variation="striped"
            backgroundColor={"antiquewhite"}
          >
            <TableRow>
              <Stack direction={{ xs: "column", sm: "row" }}>
                <Stack width={{ xs: "45ch", sm: "20ch" }} border="1px solid lightgray">
                  <TableCell>
                    <Typography style={{ color: "#2d187be0" }}>
                      Customer Type
                    </Typography>
                  </TableCell>
                </Stack>
                <Stack
                  width={{ xs: "45ch", sm: "65ch" }}
                  border="1px solid lightgray"
                >
                  <TableCell>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Switch
                        color="secondary"
                        size="small"
                        checked={isCorporate} //{inputs.isCorporate}
                        onChange={(e) => {
                          setIsCorporate(e.target.checked);
                          setInputs((values) => ({
                            ...values,
                            ["corporate"]: e.target.checked,
                          }));
                          !e.target.checked &&
                            setInputs((values) => ({
                              ...values,
                              ["clientId"]: parseInt(RETAILCLIENTID),
                            }));
                        }}
                      />
                      <Typography style={{ color: "#2d187be0" }}>
                        {isCorporate ? "Corpotate" : "Retail"}
                      </Typography>
                    </Stack>
                  </TableCell>
                </Stack>
              </Stack>
            </TableRow>

            <TableRow>
              <Stack direction={{ xs: "column", sm: "row" }}>
                <Stack
                  width={{ xs: "45ch", sm: "20ch" }}
                  border="1px solid lightgray"
                >
                  <TableCell>
                    <Typography style={{ color: "#2d187be0" }}>Name</Typography>
                  </TableCell>
                </Stack>
                <Stack
                  width={{ xs: "45ch", sm: "65ch" }}
                  border="1px solid lightgray"
                >
                  <TableCell>
                    <TextField
                      value={inputs.customerName} //&#x20AC;                             //variant='filled'                                    //defaultValue={customerName}
                      required
                      autoFocus
                      multiline
                      size="small"
                      name="customerName"
                      fullWidth
                      error={VALID_Name.current}
                      helperText={
                        VALID_Name.current && (
                          <Typography fontSize={10}>
                            Please provide Customer Name &uarr;
                          </Typography>
                        )
                      }
                      onChange={(e) => {
                        VALID_Name.current = e.target.value === "" ? true : false;
                        setInputs((values) => ({
                          ...values,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                    />
                  </TableCell>
                </Stack>
              </Stack>
            </TableRow>

            {!isCorporate && (
              <>
                <TableRow>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1 }}
                  >
                    <TableCell>
                      <Typography
                        style={{
                          color: "#2d187be0",
                          fontWeight: 600,
                          fontSize: 14,
                        }}
                      >
                        Bank Account
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <TextField
                        size="small"
                        style={{ width: "20ch" }}
                        value={inputs.customerBSB}
                        name="customerBSB"
                        fullWidth
                        error={!VALID_BSB.current}
                        helperText={
                          VALID_BSB.current ? (
                            ""
                          ) : inputs.clientBSB === "" ? (
                            ""
                          ) : (
                            <Typography fontSize={10}>BSB is INVALID</Typography>
                          )
                        }
                        placeholder={"BSB"}
                        onChange={(e) => {
                          VALID_BSB.current = validateField(
                            e.target.value,
                            BSBformat
                          );
                          // inputs.corporate === 1 ?
                          //   setInputs(values=>({...values, ['clientBSB']: e.target.value}))
                          //   :
                          setInputs((values) => ({
                            ...values,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />

                      <TextField
                        placeholder={"Account Number"}
                        size="small"
                        style={{ width: "50ch", marginLeft: 4 }}
                        value={inputs.customerAccountNumber} // setContactAccountNumber(e.target.value)
                        name="customerAccountNumber"
                        
                        error={!VALID_AccountNumber.current}
                        helperText={
                          VALID_AccountNumber.current ? (
                            ""
                          ) : inputs.customerAccountNumber === "" ? (
                            ""
                          ) : (
                            <Typography fontSize={11}>
                              Account Number format is INVALID
                            </Typography>
                          )
                        }
                        onChange={(e) => {
                          VALID_AccountNumber.current = validateField(
                            e.target.value,
                            Accountformat
                          );
                          // inputs.corporate === 1 ?
                          //   setInputs(values=>({...values, ['clientAccountNumber']: e.target.value}))
                          //   :
                          setInputs((values) => ({
                            ...values,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                    </TableCell>
                  </Stack>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography
                      style={{
                        color: "#2d187be0",
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    >
                      GC Customer ID
                    </Typography>
                  </TableCell>
                  <TableCell border="1px solid lightgray">
                    <TextField
                      fullWidth
                      value={
                        inputs.corporate == 1
                          ? inputs.GC_customerUUID
                          : inputs.GC_customercustomerUUID
                      }
                      size="small"
                      name="GC_customer"
                      disabled={true}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell border="1px solid lightgray">
                    <Typography
                      style={{
                        color: "#2d187be0",
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    >
                      GC Bank ID
                    </Typography>
                  </TableCell>
                  <TableCell border="1px solid lightgray">
                    <TextField
                      fullWidth
                      value={
                        inputs.corporate == 1
                          ? inputs.GC_bankaccountUUID
                          : inputs.GC_customerbankaccountUUID
                      }
                      size="small"
                      //name ="GC_bankaccount"
                      disabled={true}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell border="1px solid lightgray">
                    <Typography
                      style={{
                        color: "#2d187be0",
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    >
                      GC Mandate ID
                    </Typography>
                  </TableCell>
                  <TableCell border="1px solid lightgray">
                    <TextField
                      fullWidth
                      value={
                        inputs.corporate == 1
                          ? inputs.GC_mandateUUID
                          : inputs.GC_customermandateUUID
                      }
                      size="small"
                      name="GC_mandate"
                      disabled={true}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell border="1px solid lightgray">
                    {" "}
                    <Typography
                      style={{
                        color: "#2d187be0",
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    >
                      Addtess
                    </Typography>{" "}
                  </TableCell>
                  <TableCell border="1px solid lightgray">
                    <Stack sx={{ ml: "-2.9ch" }}>
                      <SearchAddress
                        destination={customerAddress}
                        setLat={lat}
                        setLng={lng}
                        setDestination={setCustomerAddress}
                        setSiteState={setCustomerState}
                        setSitePostCode={setClientPostCode}
                        setSiteSuburb={setClientSuburb}
                        size={windowWidth.current}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell border="1px solid lightgray">
                    <Typography
                      style={{
                        color: "#2d187be0",
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    >
                      Phone
                    </Typography>
                  </TableCell>
                  <TableCell border="1px solid lightgray">
                    <TextField
                      value={inputs.customerPhone} //setClientPhoneNumber(e.target.value)
                      required
                      size="small"
                      name="customerPhone"
                      fullWidth
                      error={!VALID_PhoneNumber.current}
                      helperText={
                        VALID_PhoneNumber.current ? (
                          ""
                        ) : inputs.clientPhoneNumber === "" ? (
                          ""
                        ) : (
                          <Typography fontSize={11}>
                            Phone Number format is INVALID
                          </Typography>
                        )
                      }
                      onChange={(e) => {
                        VALID_PhoneNumber.current = validateField(
                          e.target.value,
                          phonenum
                        );
                        setInputs((values) => ({
                          ...values,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                    />
                  </TableCell>
                </TableRow>
              </>
            )}

            {isCorporate && (
              <TableRow>
                <Stack direction={{ xs: "column", sm: "row" }}>
                  <Stack
                    width={{ xs: "45ch", sm: "20ch" }}
                    border="1px solid lightgray"
                  >
                    <TableCell>
                      <Typography style={{color:selectedClientName === "" ? "crimson" : "#2d187be0",}}>
                        Client
                      </Typography>
                    </TableCell>
                  </Stack>

                  <Stack
                    width={{ xs: "45ch", sm: "65ch" }}
                    border="1px solid lightgray"
                  >
                    <TableCell>
                      {/* <SelectField
                        style={{ fontFamily: "Arial", fontSize: 16 }}
                        icon={<IconArrowDropDown />}
                        iconColor="red" //variation="quiet"     //fontSize={14} //fontWeight={600}
                        size="small"
                        value={selectedClientName == "" ? null : selectedClientName}
                        options={[ "Select Client ...",  ...clients.map((c) => c.clientId !== RETAILCLIENTID && c.clientName)]}                      //options={clients}
                        descriptiveText={selectedClientName === "" ? ( <Typography fontSize={11} color={"crimson"}>Please select Client from the list  </Typography> ) : ("" )     }
                        onChange={(e) => {
                          console.log(clients);
                          setSelectedClientName( e.target.value == "Select Client ..."? "": e.target.value );
                          clients.forEach((c) => e.target.value === c.clientName ? setInputs((values) => ({...values, ["clientId"]: parseInt(c.clientId), })) : "" ); //console.log(e.target.value);
                        }}
                      ></SelectField> */}

                      <TextField
                        size="small"
                        fullWidth
                        select
                        onChange={(e) => {
                          setSelectedClientName( e.target.value == "Select Client ..." ? "" : e.target.value );
                          clients.forEach((c) => { e.target.value === c.clientName && setInputs((values) => ({...values, ["clientId"]: parseInt(c.clientId), }))        })
                        }}
                      
                      >
                        {clients.map((c, i) => (
                          <MenuItem key={i} value={c.clientName}>
                            {c.clientName}
                          </MenuItem>
                        ))}
                      </TextField>

                    </TableCell>
                  </Stack>
                </Stack>
              </TableRow>
            )}

            {isCorporate && (
              <TableRow>
                <Stack direction={{ xs: "column", sm: "row" }}>
                  <Stack
                    width={{ xs: "45ch", sm: "20ch" }}
                    border="1px solid lightgray"
                  >
                    <TableCell>
                      <Typography>Logo </Typography>
                    </TableCell>
                  </Stack>
                  <Stack
                    width={{ xs: "45ch", sm: "65ch" }}
                    border="1px solid lightgray"
                  >
                    <TableCell>
                      <TextField
                        value={inputs.customerLogo}
                        size="small"
                        fullWidth
                        name="customerLogo" //style={{width:'55ch', }}   //sx={{boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, mr:3}}
                        onChange={(e) => {
                          setInputs((values) => ({
                            ...values,
                            [e.target.name]: e.target.value,
                          }));
                        }} //onBlur={e=>setInputs(values=>({...values, ['customerLogo']: e.target.value.trim() ===''? null : e.target.value.trim()}))}
                      />
                    </TableCell>
                  </Stack>
                </Stack>
              </TableRow>
            )}

            <TableRow>
              <Stack direction={{ xs: "column", sm: "row" }}>
                <Stack
                  width={{ xs: "45ch", sm: "20ch" }}
                  border="1px solid lightgray"
                >
                  <TableCell>
                    {" "}
                    <Typography style={{ color: "#2d187be0" }}>
                      Email
                    </Typography>{" "}
                  </TableCell>
                </Stack>

                <Stack
                  width={{ xs: "45ch", sm: "65ch" }}
                  border="1px solid lightgray"
                >
                  <TableCell>
                    <TextField
                      value={inputs.customerEmail}
                      required
                      fullWidth
                      size="small"
                      name="customerEmail"
                      error={!VALID_email.current}
                      helperText={
                        VALID_email.current ? (
                          ""
                        ) : inputs.clientEmail == "" ? (
                          ""
                        ) : (
                          <Typography fontSize={11}>
                            Email format is INVALID &#128561;
                          </Typography>
                        )
                      }
                      onChange={(e) => {
                        VALID_email.current = validateField(
                          e.target.value,
                          mailformat
                        ); //setCustomerEmail(e.target.value)
                        setInputs((values) => ({
                          ...values,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                    />
                  </TableCell>
                </Stack>
              </Stack>
            </TableRow>

            {isCorporate && (
              <TableRow>
                <Stack direction={{ xs: "column", sm: "row" }}>
                  <Stack
                    width={{ xs: "45ch", sm: "20ch" }}
                    border="1px solid lightgray"
                  >
                    <TableCell>
                      <Typography style={{ color: "#2d187be0" }}>
                        CC UUID
                      </Typography>
                    </TableCell>
                  </Stack>
                  <Stack
                    width={{ xs: "45ch", sm: "65ch" }}
                    border="1px solid lightgray"
                  >
                    <TableCell>
                      <TextField
                        value={inputs.customerCCUUID}
                        required
                        fullWidth
                        size="small"
                        name="customerCCUUID"
                        // error={!VALID_email.current}
                        // helperText={<Typography fontSize={11} color="blue">Obtain the ID from the Carton Cloud app</Typography>}
                        onChange={(e) => {
                          //VALID_email.current  = validateField(e.target.value, mailformat) //setCustomerEmail(e.target.value)
                          setInputs((values) => ({
                            ...values,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                    </TableCell>
                  </Stack>
                </Stack>
              </TableRow>
            )}
          </Table>
        </Stack>

        <Stack width={{ sm: "45ch", sm: "85ch" }} sx={{ flex: "1 1 100%", alignItems: "center", mt: 2 }}>
          {action === "update" && isCorporate && (
            <NewContact entityID={id} entitySearchField={"customerId"} />
          )}
        </Stack>

        <Stack width={{ sm: "45ch", sm: "85ch" }} direction={{ xs: "column", sm: "row" }} gap={5} >
        
          {/* spacing={4} paddingTop={3} sx={{flex:'1 1 100%', justifyContent:'center', alignItems:'center', mt: action =='update' ? 12 : -35}} > */}
          {active === 1 && (
            <Button
              className="Button1" //disabled={selectedClientName == ""}
              onClick={action === "update" ? handleUpdate : handleAdd}
              variant="outlined"
              sx={{ fontSize: 11, borderRadius: "5px" }}
            >
              <Typography style={{ fontSize: "12px", fontWeight: 800 }}>
                {" "}
                {action === "update" ? "Update " : "Add "}{" "}
              </Typography>
            </Button>
          )}
          {action === "update" && (
            <Button
              className="Button1"
              onClick={openDialog} //{active === 1? handleDelete: ReActivate}
              variant="outlined"
              sx={{ color: "red", fontSize: 11, borderRadius: "5px" }}
            >
              {" "}
              <Typography
                style={{ width: "45ch", fontSize: "12px", fontWeight: 800 }}
              >
                {" "}
                {active === 1 ? "Deactivate" : "Re-Activate"}
              </Typography>
            </Button>
          )}
          <Button
            className="Button1"
            onClick={handleClose}
            variant="outlined"
            sx={{ fontSize: 11, borderRadius: "5px" }}
          >
            {" "}
            <Typography style={{ fontWeight: 800, fontSize: "12px" }}>
              {" "}
              close{" "}
            </Typography>
          </Button>
        </Stack>

        <DialogueResponce
          isOpen={open}
          setIsOpen={setOpen}
          setValue={setDialogValue}
          title={`${
            inputs.active !== 1 ? "Reactivation Warning" : "Deactivation Warning"
          }`}
          dialogueTxt={`You are about to change this customer from ${
            inputs.active === 1 ? "active" : "inactive"
          } to ${
            inputs.active === 1 ? "inactive" : "active"
          }. Would You like to proceed with ${
            inputs.active !== 1 ? "activation" : "deactivation"
          }?`}
        />
      </Stack>

    </Box>
  );
};
export default NewCustomer;




//   {/* <Autocomplete
//                     name='clientList'
//                     fontSize={12}
//                     fontWeight={600}
//                     style={{fontFamily:'Arial'}}
//                     value={selectedClientName}    //{console.log(clientName)}                             //value={ selectedClientName}
//                     freeSolo={true}
//                     required
//                     options={clientName.name}
//                     autoHighlight
//                     size='small'
//                     onChange={(event, newInputValue) => {
//                         setSelectedClientName(newInputValue)
//                         clients.map(c => newInputValue === c.clientName ? setInputs(values=>( {...values, ['clientId']: parseInt(c.clientId)})) : '')
//                     } }
//                     renderInput={(params) => (
//                         <TextField {...params}
//                             helperText = { selectedClientName ===''?  <Typography fontSize={11}>Select Client from the list</Typography>:''}
//                             error ={selectedClientName === ''? true:false}
//                             inputProps = {{...params.inputProps, type: 'search', }}
//                         />
//                     )}
//                 />   */}

// {/* <Dialog open={open} onClose={handleCloseDialog}>
//     <form onSubmit={handleSubmit}>
//         <DialogTitle><Typography style={{ fontWeight:500 }}>DEACTIVATION WARNING</Typography></DialogTitle>
//         <DialogContent>
//         <DialogContentText>
//             <Typography style={{fontSize:16}}>{`You are about to Deactivate this Customer. If You wish to proceed - type 'yes' and then click OK button`}</Typography>
//         </DialogContentText>
//             <TextField
//                 autoFocus
//                 margin="dense"
//                 id="name"
//                 value={dialogValue}
//                 placeholder='No'
//                 type="text"
//                 variant="standard"
//                 onChange={(e) => setDialogValue(e.target.value) }
//             />

//         </DialogContent>
//         <DialogActions>
//             <Button onClick={handleCloseDialog}><Typography style={{fontWeight:500}}>Cancel</Typography></Button>
//             <Button type="submit"><Typography style={{fontWeight:500}}>OK</Typography></Button>
//         </DialogActions>
//     </form>
// </Dialog>  */}

// if (action ==='update' && loaded){
//     handleGetDataForUpdate()
//     setLoaded(false)
// }

// const handleDelete = ()=>{

//     //let a = prompt(`\n\nWARNING:\n\nYou are about to Deactivate this Customer\nIf You wish to proceed - type 'yes' and then click OK button\n`,'NO')
//     openDialog()

//     // if(value===null)(a="no")
//     // console.log(value)

//     // if (value.toLowerCase() === 'yes'){
//     //     //deleteData(`${DOMAIN}${process.env.REACT_APP_API_GET_CUSTOMER_DATA}delete/${id}/d`) /// `http://localhost:3002/api/${type}/delete/${id}`
//     //     navigate(-1)
//     // }
// }

// {/* <Menu  menuAlign="end"  size="small"
//  onOpenChange={(open)=>console.log(open)}
//  trigger={
//     <MenuButton size="small" width="50%"> Custom trigger button 🚀</MenuButton>
//   }

// >
//     <MenuItem onClick={() => alert('Download')}>     Download         </MenuItem>
//     <MenuItem onClick={() => alert('Create a Copy')}>Create a Copy    </MenuItem>
//     <MenuItem onClick={() => alert('Mark as Draft')}>Mark as Draft    </MenuItem>
//     <MenuItem isDisabled onClick={() => alert('Delete')}>  Delete     </MenuItem>
// </Menu> */}
////noValidate autoComplete="off" border={'1px solid red'}

//   {/* <Link  to={{pathname: `/customers`}}  style={{ textDecoration: "none" }}  > */}
//             {/* </Link> */}
//              {/* //<ButtonStyle primary onClick={handleClose} > Close </ButtonStyle> */}
//             {/* <Link  to={{pathname: `/customers`}} style={{ textDecoration: "none" }}  > */}

// async function testData(Url){
//     const response = await fetch(Url);
//     const responseData = await response.json()
//     console.log(responseData)
//     //console.log(responseData[0]['COUNT(userId)']) }

// async function getData(Url) {
//     const response = await fetch(Url);
//     const responseData = await response.json();
//     const data=responseData[0]
//     const keys= Object.keys(data)
//     // setBusinessName(data.clientName)
//     // setInputs(values=>({...values, ['businessName']: data.clientName, isValidated: true,}));
//     // setEmailBusness(data.clientEmail)
//     // setInputs(values=>({...values, ['businessEmail']: data.clientEmail, isValidated: true,}));
//     // setRegisteredBisinessName(data.clientRegName)
//     // setInputs(values=>({...values, ['registeredBusinessName']: data.clientRegName, isValidated: true,}));
//     // setAbn(responseData[0].abn)
//     // setInputs(values=>({...values, ['abn']: data.abn, isValidated: true,}));
//     // setContactNumberBusness(data.clientPhoneNumber)
//     // setInputs(values=>({...values, ['businessContactNumber']: data.clientPhoneNumber, isValidated: true,}));

// }

//import { styled } from '@mui/material/styles';
//import { Link } from "react-router-dom";
//import { css, cx } from '@emotion/react';
//import {ButtonStyle, Thing, Input, PasswordInput, Label, LabelText, EmailInput } from './Styles.jsx';
