// import MainFrame from '../../components/MainFrame.jsx';// import Sidebar from "../../components/Sidebar";// import Navbar from "../../components//Navbar";//import AddNewClientScreen from '../../components/AddNewClientScreen.js'
import * as React from 'react';
// import Box from '@mui/material/Box';
// import SpeedDial from '@mui/material/SpeedDial';
// import SpeedDialIcon from '@mui/material/SpeedDialIcon';
// import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import NewClient from '../../components/newitem/NewClient.jsx';
import NewCustomer from '../../components/newitem/NewCustomer.jsx';
import NewUser from '../../components/newitem/NewUser.jsx';
import NewSite from '../../components/newitem/NewSite.jsx';
import NewPart from '../../components/newitem/NewPart.jsx';
import NewContractor from '../../components/newitem/NewContractor.jsx';
import NewWarehouse from '../../components/newitem/NewWarehouse.jsx';
import {NoPage} from '../../pages/nopage/NoPage.jsx'
import NewJob from '../../components/newitem/NewJob';
import ApplicationBar from '../../components/AppBar.jsx';
import './new.scss'
import { Stack } from '@mui/material';

const actions = [
  { icon: <FileCopyIcon />, name: 'Copy' },
  { icon: <SaveIcon />, name: 'Save' },
  { icon: <PrintIcon />, name: 'Print' },
  { icon: <ShareIcon />, name: 'Share' },
  { icon: 'hello', name: 'hello' },
  { icon: 'deativate', name: 'deactivate' },
  { icon: 'close', name: 'close' },
];

export const New = ({type, action}) => {
  


  return (
        // <div  className='new'>
        //     <Sidebar />
        //     <div className="newContainer">
        //       <Navbar />

        <>
         < ApplicationBar />
          <Stack className="newWrapper" padding={{xs:'15px', sm:'21px', xl:'35px'}}>
            {/* <div className="newItem" > */}
                  { type==='clients' ?
                      <NewClient action={action}/>
                    :
                    type==='customers'?
                      <NewCustomer action={action}/>

                    :
                    type==='users'?
                      <NewUser action={action}/>

                    :                      
                    type==='jobbook'?
                      <NewJob action={action}/>

                    :
                    type==='sites'?
                      <NewSite action={action}/>

                    :
                    type==='contractors'?
                      <NewContractor action={action}/>

                    :
                    type==='jobparts'?
                      <NewPart action={action}/>

                    :
                    type==='warehouses'?
                      <NewWarehouse action={action}/>

                    : 
                    <NoPage />
                  }
            {/* </div> */}
            {/* <Box sx={{ height: 320, width:5, transform: 'translateZ(0)', flexGrow: 1 }}>
              <SpeedDial
                ariaLabel="SpeedDial"
                sx={{ position: 'absolute', bottom: 6, right: 16 }}
                icon={<SpeedDialIcon />}
              >
                {actions.map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={()=>
                      console.log(action.name)
                    }
                  />
                ))}
              </SpeedDial>
            </Box> */}
          </Stack>
        </>    


      //     </div>
      // </div>
  )
}

New.defaultProps = {type:'jobbook', action:'new'}

  // const [isOpen, setIsOpen] = useState(false);
  //const toggleModal=()=>{setIsOpen(!isOpen)}
  //onClick={toggleModal}  