import React, { useState, createContext, useEffect, useRef } from "react"; //import './app.scss';//import '../../Client/src/styles/main.scss';
import AppRoutes from "./components/AppRoutes.jsx";
import { Amplify, Hub } from "aws-amplify"; //Auth Cache
import {
  Authenticator,
  useTheme,
  Heading,
  View,
  Image,
  Button,
  useAuthenticator,
  CheckboxField,
  Flex,
  Grid,

} from "@aws-amplify/ui-react"; // withAuthenticator,
import { useLocation } from 'react-router-dom';
import "../src/pages/home/home.scss";
import "@aws-amplify/ui-react/styles.css";
import pic from "../src/resource/images/juxaroo.jpg"; //import { useNavigate, useLocation } from "react-router-dom";
import config from "./aws-exports";
import { useJsApiLoader } from "@react-google-maps/api"; // import {useUnload} from '../../Client/src/util/Functions.jsx';//import { } from '@mui/material';
import { Typography } from "@mui/material";
import CryptoJS from "crypto-js"; 

Amplify.configure(config);

const DEBUG = true; //process.env.REACT_APP_DEBUG;
const productionHost = process.env.REACT_APP_API_HTTP + window.location.host;
const localHost =  process.env.REACT_APP_API_HTTP +  process.env.REACT_APP_API_LOCAL_DOMAIN +  process.env.REACT_APP_API_LOCAL_PORT;
const DOMAIN = `${  process.env.NODE_ENV === "production" ? productionHost : localHost}`;

//EXPORT logged in user details for other modules
export const UserLogInContext = createContext("Default value"); //{ signOut, user }

const toHashPassword = (pwd) => {
  const ciphertext = CryptoJS.AES.encrypt( pwd, process.env.REACT_APP_SECRET_KEY).toString();
  console.log(ciphertext);
  return ciphertext;
};
const verifyHashedPassword = (pwd) => {
  try {
    const bytes = CryptoJS.AES.decrypt(pwd.trim(), process.env.REACT_APP_SECRET_KEY);
    //DEBUG && console.log(bytes.toString(CryptoJS.enc.Utf8))
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (err) {
    //DEBUG && console.log('OLD PASSWORD ERROR :', err);
    return "Old Password cannot be retreaved";
  }
};
function App(props) {
  const [userId, setUserId] = useState(1); 
  const [userSub, setUserSub] =useState(null);   //const [sub, setSub]= useState('');  //const [alert, setAlert]= useState(true);
  const [libraries, setLibraries] = useState([ "localContext", "places", "geometry", "marker"]);

  const psw = useRef();
  const username = useRef();
  const firstName = useRef();
  const familyName = useRef();
  const email = useRef();
  const userType = useRef();
  const userSubType = useRef()

  useEffect(()=>{

    if(userSub !== null){
      addNewUsertDataToDB({ 
        'name':username.current,
        'userFullName':firstName.current + " " + familyName.current,
        'passWord': toHashPassword(psw.current),
        'userEmail':  email.current ,
        'sub': userSub,
        'userType':userType.current, //'Retail Customer',
        'userSubType':userSubType.current, //'Retail Customer'
      })
      setUserSub(null)
    }
  },[userSub])


  useEffect(() => {
    Hub.listen("auth", (data) => {
      // console.log("----------------------------------",window.document.body.style.zoom)
      const { payload } = data; //window.document.body.style.zoom='93%';
      DEBUG && console.log(payload); // DEBUG && console.log(data)      //  console.log(`A new auth event has happened at: ${new Date()} `, payload.data.attributes['custom:Role']??"No Role has been setup") //Date.now()

      if (payload.event === "signIn") {
        //role = payload.data.attributes['custom:Role'] ?? "No Role has been setup"         //window.location.assign(`${process.env.NODE_ENV==='production' ? 'https://autom8systems.com.au/': 'http://localhost:3000/'} `)
        //  TURN BACK ON getSub(`${url_main}${payload.data.attributes.sub}`);
        setUserId(payload.data.attributes.sub); 
        //setLoging(false)        //window.location.assign(`${process.env.NODE_ENV==='production' ? 'https://autom8systems.com.au/': 'http://localhost:3000/'} `)
       console.log(payload.data, " -----------" + payload.message);
        sessionStorage.setItem("username", payload.data.username);
        sessionStorage.setItem("test", "testing");
      } else if (payload.event === "signOut") {
        //setLoging(false)        //document.cookie = "username=John Jack; expires=Thu, 1 Dec 1970 12:00:00 UTC";        // window.localStorage.clear()           // window.location.reload()

        DEBUG && console.log(payload.data.username, " ---------- " + payload.message);
        //window.location.assign(`${process.env.NODE_ENV==='production' ? 'https://autom8systems.com.au/': 'http://localhost:3000/'} `)
        ///setSub('')
      } else if (payload.event == "signUp") {
        console.log(payload.event);
        console.log(payload.data.userSub);
        setUserSub(payload.data.userSub)
        //window.location.reload();
    
      } else {
        console.log(payload.event);
        console.log(payload);
        if(payload.event == 'signIn_failure'){
          //alert('You have not confirm your email address')
          //refresh()
          //window.location.href = window.location.href.split('#')[0];
          //window.location.href = window.location.pathname;

          ///return false;
        
        }
      }
    });
  }, []);


  const addNewUsertDataToDB = async (BodyInput) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf-8"},
      body: JSON.stringify({BodyInput}),
    };

    const response = await fetch(`${DOMAIN}${process.env.REACT_APP_API_ADD_NEW_USER}`, requestOptions);
    DEBUG && console.log(response.status);
    const responseData = await response.json();
    DEBUG && console.log(responseData) 
    if (responseData.isOK === 'Yes'){
      //window.location.reload(true)
      alert('We have sent you email with confirmation code ')
      
    }
  };


  const refresh= ()=> {    
    setTimeout(function () {
        //window.location.reload(true)
        window.location.replace(window.location.origin)
    }, 100);
}
  //   useEffect(() => {
  //     document.addEventListener('click', ()=>console.log('e'));
  //     return () => { document.removeEventListener('click', ()=>console.log('e'))};
  //  },[]);

  const formFields = {
    signIn: {
      username: {
        labelHidden: true,
        placeholder: "Username", //dialCodeList: ['+1', '+123', '+227', '+229']//
      },
      password: {
        labelHidden: true,
        placeholder: "Password", //dialCodeList: ['+1', '+123', '+227', '+229']//
      },
    },
    signUp: {
      username: { 
        placeholder: 'User Name',
        order: 1,
        isRequired:true,
      },
      email: {
        autocomplete:'email',
        placeholder: 'Email',
        isRequired:true,
        order: 2,
      },
      password: {
        placeholder: 'Password',
        isRequired:true,
        order: 3,
      },
      confirm_password: {
        isRequired:true,
        placeholder: 'Confirm Password',
        order: 4,
      },
      given_name: {
        isRequired:true,
        placeholder: 'Given Name',
        order: 5,
      },
      family_name: {
        placeholder: 'Family Name',
        order: 6,
      },   
      address: {
        order: 7,
        placeholder: 'Address',
      },
      phone_number: {
        order: 8,
        placeholder: 'Phone',
        //defaultcountrycodevalue:'+61',
        fontFamily:'Arial',
      },
      'custom:CustomerRole': {
        defaultValue:'placeholder',
        display:'none'
      },
      'custom:Role': {
        defaultValue:'Retail Customer',
        display:'none'
      },
      'custom:account': {
        defaultValue:'F8',
        display:'none'
      },
      'custom:SubRole': {
        defaultValue:'Retail Customer',  
        display:'none'
      },
      name: {
        defaultValue: '', 
        display:'none',
      }, 
    }
  };

  const AuthenticatorComponent = {
    Header() {
      const { tokens } = useTheme();
      const {route, hasValidationErrors, } = useAuthenticator();
      console.log(route, hasValidationErrors, )
      console.log(useAuthenticator())
      console.log(tokens)
      return (
        <View
          textAlign="center"          //padding={tokens.space.xs}
          border="1px solid transparent"
          borderRadius="2px"          //marginTop="15ch"
          backgroundColor={`var(${tokens.colors.blue['20'].name})`}
          height='8ch'
        
        >
          <Image
            alt="logo"
            //marginTop={1}
            padding={tokens.space.xs}
            height={'80px'}
            objectPosition="50% 50%"
            src={pic} //"https://docs.amplify.aws/assets/logo-dark.svg"
            overflow='hidden'
            onClick={() => {
              //window.location.reload(true)
              refresh();
              //return false;
            }
            }
          />
        </View>
        // <Heading
        //   backgroundColor="var(--amplify-colors-white)"
        //   //width='100%'  //height="auto"
        //   border='.5px solid black'
        //   borderRadius='5px'
        // >
          // <Image
          //     width="100%"        //     marginTop={5}
          //     height={115}        //     objectFit="none"
          //     objectPosition="50% 50%" //     src={pic}        //     alt="Sign In Autom8"        
          //     overflow='hidden'        //     onClick={() => alert('📸 Say cheese!')}
          // />
        // </Heading>   Name: "custom:Role",
      );
    },
  
    Footer() {
      const { tokens } = useTheme();

      return (
        <View
          textAlign="center"           //border="1px solid gray"
          borderRadius="2px"
          backgroundColor={`var(${tokens.colors.blue['20'].name})`}// "var(--amplify-colors-white)"
          padding={tokens.space.xs}
          border="1px solid transparent"          //backgroundColor='lightblue'
        >
          <Typography fontSize={12} fontWeight={600} fontFamily={'Arial'} color={`var(${tokens.colors.blue['80'].name})`}>&copy; All Rights Reserved</Typography>
          <Button
              fontWeight="normal"
              onClick={() => {
                // window.location.reload(true)
                refresh();
                return false;
              }}
              marginTop={'6ch'}
              fontFamily={'Arial'}
              fontSize={'10px'}
              variation="link"
              size="small"
          > Back to Landing page</Button>
        </View>
      );
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            textAlign="center"             //color="darkred"
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          > 
            <Typography fontSize={20} fontWeight={600} fontFamily={'Arial'}> Sign into your Account </Typography>
          </Heading>
        );
      },

      Footer() {
        const { toResetPassword } = useAuthenticator();       //  const { toSignUp } = useAuthenticator();         //const { tokens } = useTheme()      //  console.log(useAuthenticator());
        const { tokens } = useTheme();

        return (
          <View 
            textAlign="center"
            padding={`${tokens.space.small} 0 0 ${tokens.space.small}`}
          >
            <Button
              onClick={toResetPassword}
              size="small"
              //  isFullWidth
              //width={160} //paddingLeft={-1}
              marginBottom={20}
              variation="link" //  variation="primary"
              fontFamily={'Arial'}
              color= {`var(${tokens.colors.blue['100'].name})`} 
            > Forgot Password 
            </Button>
          </View>
        );
      },
    },

    ResetPassword: {
      Header() {
        const { tokens } = useTheme()

        return (
          <Heading
            //color="darkred"
            textAlign="center"
            padding={`${tokens.space.xl} 0 ${tokens.space.xl} ${tokens.space.xl}`}
            level={3}           //  marginBottom={20} //  padding={`${tokens.space.l} 0 0 ${tokens.space.l}`}
          >  <Typography fontSize={20} fontWeight={600} fontFamily={'Arial'}> Reset Password </Typography>
          </Heading>                                  //  {/* //<Heading>Reset your password</Heading> //パスワード再交付 */}
        );
      },
    },

    SignUp: {
      FormFields() {
        const { validationErrors } = useAuthenticator();

        return (
          <>
            <Authenticator.SignUp.FormFields />

            {/* Append & require Terms and Conditions field to sign up  */}
            <CheckboxField
              errorMessage={validationErrors.acknowledgement}
              hasError={!!validationErrors.acknowledgement}
              name="acknowledgement"
              value="yes"
              label="I agree with the Terms and Conditions"
            />
          </>
        );
      },      
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            textAlign="center"   //color="darkred"
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            <Typography  fontSize={20} fontWeight={600} fontFamily={'Arial'}> Sign Up for Account 🚀 </Typography>
          </Heading>
        );
      },
  
        // Footer() {          
        //   const { toSignUp, validationErrors } = useAuthenticator();           
        //    return (
        //     <View textAlign="center"  fontFamily={'Arial'}  >
        //       <CheckboxField
        //             errorMessage={validationErrors.acknowledgement}
        //             hasError={!!validationErrors.acknowledgement}
        //             name="acknowledgement"
        //             value="yes"
        //             label="I agree with the Terms and Conditions"
        //       />
        //       {/* <Button
        //         onClick={toSignUp}
        //         size="large"
        //         isFullWidth={true}
        //         width={410} //paddingLeft={-1}
        //         marginBottom={20}
        //         variation="link" //  variation="primary"
        //       >
        //         <Typography fontSize={16} fontWeight={600}> SingUp 🚀 </Typography>
        //       </Button> */}
        //     </View>
        //    );
        // },
    },
  };

  const service = {
    async validateCustomSignUp(formData) {
      console.log(formData);
      psw.current = formData.password
      username.current =formData.username;
      firstName.current =formData.given_name;
      familyName.current = formData.family_name;
      email.current = formData.email;
      userType.current = formData['custom:Role'];
      userSubType.current =formData['custom:SubRole'];

      if (!formData.acknowledgement) { return { acknowledgement: "You must agree to the Terms and Conditions" } }
    },
  };

  const signUpAttributes = [
    "email",
    "family_name",
    "given_name",
    "address",
    // 'birthdate',
    // 'email',
    // 'family_name',
    // 'gender',
    // 'given_name',
    // 'locale',
    // 'middle_name',
     'name',
    // 'nickname',
     'phone_number',
    // 'picture',
    // 'preferred_username',
    // 'profile',
    // 'updated_at',
    // 'website',
    // 'zoneinfo',
  ];

  const url_main = `${DOMAIN}${process.env.REACT_APP_API_USER_GET_SUB}`;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP,
    libraries: libraries,
    region: "Australia",
  });

  const getSub = async (url) => {
    const response = await fetch(url);
    const responseData = await response.json();
    console.log(responseData[0]);
    setUserId(responseData[0]?.userId);     //responseData[0].active === 0 && setLoging (true)
  };

  // const location = useLocation();
  // console.log(location.pathname);

  return (
    <Grid >
      <Authenticator    
        className='main'
        loginMechanisms={["username"]} //{['email']}/
        formFields={formFields}
        components={AuthenticatorComponent}
        variation="modal" //backgroundColor='green'
        initialState={props.flag ? 'signIn' :'signUp'}
        hideSignUp={props.flag}
        services={service}
        signUpAttributes={signUpAttributes}
      
      >
        {({ signOut, user }) => (                

          <UserLogInContext.Provider      
            value={{
              user: user.username, //signInUserSession.idToken.payload['cognito:username'], //loggedInUser,  //  userName: user.attributes.given_name + " " + user.attributes.family_name,
              group: user.signInUserSession.idToken.payload["cognito:groups"] ?? ["NOGROUP",], //loggedInGroup,
              subId: user.attributes.sub,
              role: user.attributes["custom:Role"] ?? "No Role Detected", //user.signInUserSession.idToken.payload['custom:Role'] ?? "No Role Detected",
              subrole: user.attributes["custom:SubRole"] ?? "No Sub Role Detected", //user.signInUserSession.idToken.payload['custom:SubRole'] ?? "No SubRole Detected",
              customerrole: user.attributes["custom:CustomerRole"] ?? "No CustomerRole Detected", //user.signInUserSession.idToken.payload['custom:CustomerRole'] ?? 'No User Name',
              account: user.attributes["custom:account"] ?? "No Account Detected",
              userfullname: user.attributes.name,
              userId: userId, //fullName:user.attributes.sub,// getSub(`${DOMAIN}${process.env.REACT_APP_API_USER_GET_SUB}${user.attributes.sub}`),
              time: user.signInUserSession.idToken.payload.auth_time,
              isLoaded: isLoaded,
              isSuperUser:  user.signInUserSession.idToken.payload["cognito:groups"] == "Admin",              //setLoging:setLoging,
              logout: signOut,
            }}
          >
                {console.log(user)}
            <AppRoutes />
          </UserLogInContext.Provider>
          
        )}
      </Authenticator>
    </Grid>  
  );
}

export default App;



// tokens:
// borderWidths : {small: {…}, medium: {…}, large: {…}}
// colors : 
  // background// :// {primary: {…}, secondary: {…}, tertiary: {…}, quaternary: {…}, disabled: {…}, …}
  // black// : // {name: '--amplify-colors-black', path: Array(2), value: 'hsl(0, 0%, 0%)', original: 'hsl(0, 0%, 0%)', toString: ƒ}
  // blue// : // {10: {…}, 20: {…}, 40: {…}, 60: {…}, 80: {…}, 90: {…}, 100: {…}}
  // border// : // {primary: {…}, secondary: {…}, tertiary: {…}, disabled: {…}, pressed: {…}, …}
  // brand// : // {primary: {…}, secondary: {…}}
  // font// : // {primary: {…}, secondary: {…}, tertiary: {…}, disabled: {…}, inverse: {…}, …}
  // green// : // {10: {…}, 20: {…}, 40: {…}, 60: {…}, 80: {…}, 90: {…}, 100: {…}}
  // neutral// : // {10: {…}, 20: {…}, 40: {…}, 60: {…}, 80: {…}, 90: {…}, 100: {…}}
  // orange// : // {10: {…}, 20: {…}, 40: {…}, 60: {…}, 80: {…}, 90: {…}, 100: {…}}
  // overlay// : // {10: {…}, 20: {…}, 30: {…}, 40: {…}, 50: {…}, 60: {…}, 70: {…}, 80: {…}, 90: {…}}
  // pink// : // {10: {…}, 20: {…}, 40: {…}, 60: {…}, 80: {…}, 90: {…}, 100: {…}}
  // purple// : //{10: {…}, 20: {…}, 40: {…}, 60: {…}, 80: {…}, 90: {…}, 100: {…}}
  // red// : // {10: {…}, 20: {…}, 40: {…}, 60: {…}, 80: {…}, 90: {…}, 100: {…}}
  // shadow// : // {primary: {…}, secondary: {…}, tertiary: {…}}
  // teal// : // {10: {…}, 20: {…}, 40: {…}, 60: {…}, 80: {…}, 90: {…}, 100: {…}}
  // transparent// : // {name: '--amplify-colors-transparent', path: Array(2), value: 'transparent', original: 'transparent', toString: ƒ}
  // white// : // {name: '--amplify-colors-white', path: Array(2), value: 'hsl(0, 0%, 100%)', original: 'hsl(0, 0%, 100%)', toString: ƒ}
  // yellow// : // {10: {…}, 20: {…}, 40: {…}, 60: {…}, 80: {…}, 90: {…}, 100: {…}}

// components : {alert: {…}, authenticator: {…}, badge: {…}, button: {…}, card: {…}, …}
// fontSizes :  {xxxs: {…}, xxs: {…}, xs: {…}, small: {…}, medium: {…}, …}
// fontWeights :  {hairline: {…}, thin: {…}, light: {…}, normal: {…}, medium: {…}, …}
// fonts :  {default: {…}}
// lineHeights :  {small: {…}, medium: {…}, large: {…}}
// opacities :  {0: {…}, 10: {…}, 20: {…}, 30: {…}, 40: {…}, 50: {…}, 60: {…}, 70: {…}, 80: {…}, 90: {…}, 100: {…}}
// outlineOffsets :  {small: {…}, medium: {…}, large: {…}}
// outlineWidths/ :  {small: {…}, medium: {…}, large: {…}}
// radii :  {xs: {…}, small: {…}, medium: {…}, large: {…}, xl: {…}, …}
// shadows :  {small: {…}, medium: {…}, large: {…}}
// space :  {zero: {…}, xxxs: {…}, xxs: {…}, xs: {…}, small: {…}, …}
// time :  {short: {…}, medium: {…}, long: {…}}
// transforms :  {slideX: {…}}





// Data : codeDeliveryDetails: 
//  AttributeName :  "email"
// DeliveryMedium :  "EMAIL"
// Destination :  "a***@f***"

// user: CognitoUser
// Session :  null
// authenticationFlowType :  "USER_SRP_AUTH"
// client : Client
// endpoint : "https://cognito-idp.ap-southeast-2.amazonaws.com/"
// fetchOptions :  {}

// keyPrefix :  "CognitoIdentityServiceProvider.64h81hl6ntvf7m36lr17hb2h7e"
// pool :  CognitoUserPool {userPoolId: 'ap-southeast-2_NGws2rnc0', clientId: '64h81hl6ntvf7m36lr17hb2h7e', client: Client, advancedSecurityDataCollectionFlag: true, storage: Storage, …}
// signInUserSession : null
// storage : Storage {length: 0}
// userDataKey :  "CognitoIdentityServiceProvider.64h81hl6ntvf7m36lr17hb2h7e.aa3.userData"
// username : "aa3"

// userConfirmed : false
// userSub :  "84878d03-fd23-49fc-97b0-137408666dd8"

// event : "signUp"
// message :  "aa3 has signed up successfully"
































// const AlertExample = () => {
//   return (
//       <Alert
//           hasIcon={true}
//           heading="Alert "
//           variation="error"
//           isDismissible={true}
//         //  onDismiss={() =>setAlert(false)}
//         >If you want to set up New or Reset fogotten password select 'Set New / Reset Password' button, otherwise provide username, password and select 'Sign in' button.
//         </Alert>
//   )
// };

//    {/* {alert &&  <AlertExample/>  } */}

////////////////////////////////////////
//   useEffect(() => {
//     const handleTabClose = (e) => {
//       e.preventDefault();
//       console.log('beforeunload event triggered');
//       log.current=true
//       console.log(e);
//       console.log(log.current);
//       //window.location.reload()
//       return (e.returnValue ='Are you sure you want to exit?');
//     };

//     window.addEventListener('beforeunload', handleTabClose);

//     return () => {
//       log.current= !log.current
//       window.removeEventListener('beforeunload', handleTabClose)
//       console.log('tttttttttttttt', log.current)
//     };
//  });
//////////////////////////////////////////////////

//<View
//textAlign="center" paddingTop={tokens.space.xl} width="100%"
//>
//   <Text
//     color='darkred'
//     fontWeight={700}
//     fontSize={26}
//     fontStyle="normal"
//     //textDecoration="none"
//   >Sign In</Text>
// </View>

// Footer() {
//   const { tokens } = useTheme()
//   return (
//     <View textAlign="center" padding={tokens.space.xxxl}>
//       <Text color={`${tokens.colors.neutral['80']}`}>
//         &copy; All Rights Reserved
//       </Text>
//     </View>
//   )
// },

// signUp: {
//   Header() {
//     const { tokens } = useTheme()
//     return (
//        <View textAlign="center" paddingTop={tokens.space.xl} width="100%">
//           <Text
//             color='darkred'
//             fontWeight={700}
//             fontSize={26}
//             fontStyle="normal"
//             //textDecoration="none"
//           >Create </Text>
//         </View>
//     )
//   },

//   Footer() {
//     const { tokens } = useTheme()
//     return (
//       <View textAlign="center" padding={tokens.space.xxxl}>
//         <Text color={`${tokens.colors.neutral['80']}`}>
//           &copy; All Rights Reserved hello
//         </Text>
//       </View>
//     )
//   },  // },

//  {/* {debug && console.log(user)} */}
//        {/* {debug && console.log(user.attributes['custom:Role']??"No Role has been setup")}
//        {debug && console.log(user.attributes['custom:CustomerRole']??"No Customer Role has been setup")} */}
//        {/* <button onClick={signOut}>Sign out</button> */}

// const components = {
//   tokens: {
//     colors: {
//       background: {
//         primary: { value: tokens.colors.neutral['90'].value, },
//         secondary: {value: tokens.colors.neutral['100'].value, },
//       },
//       font: {
//         interactive: { value: tokens.colors.red['60'].value, },
//         default: {
//          variable: { value: 'Raleway, sans-serif' },
//            static: { value: 'Raleway, sans-serif' },
//         },
//       },
//       brand: {
//         primary: {
//           '10': tokens.colors.teal['100'],
//           '80': tokens.colors.teal['40'],
//           '90': tokens.colors.teal['20'],
//           '100': tokens.colors.teal['10'],
//         },
//       },
//     },
//     components: {
//       tabs: {
//         item: {
//             _focus: {
//               color: {value: tokens.colors.white.value,},
//             },
//             _hover: {
//               color: {value: tokens.colors.yellow['60'].value,},
//             },
//             _active: {
//               color: { value: tokens.colors.white.value, },
//             },
//         },
//       },
//     },
//  },
// };
///////////////

// {/* {console.log(user.signInUserSession.idToken)},   */}
//           {/* {console.log(user)}  */}
//           {/* <button width="30px" height ="10px" onClick={checkUser}>Check User</button> */}
//           {/* {localStorage.setItem('isReloaded', true)} */}

//           {/* {console.log(Auth.currentlyAuthenticatedUser())}          */}

// signOut={signOut}
// loggedInUser={loggedInUser}
// loggedInGroup={loggedInGroup}

//useEffect(() => {
// Hub.listen('auth', (data) => {
//    const { payload } = data
//    console.log('A new auth event has happened: ', data)
//    if (payload.event === 'signIn') {
//      console.log(payload.message)
//      console.log(payload.data.username )
//       //checkUser()
//      setLoggedInUser(user.signInUserSession.idToken.payload['cognito:username'])
//      setLoggedInGroup(user.signInUserSession.accessToken.payload['cognito:groups'][0])

//    }
//    if (payload.event === 'signOut') {
//       setLoggedInUser('')
//       setLoggedInGroup("")
//       console.log(payload.message,'  a user has signed out!')
//       console.log(payload.data.username )

//       console.log(loggedInUser)
//    }
// // })
// setLoggedInUser(user.signInUserSession.idToken.payload['cognito:username']||'')
// setLoggedInGroup(user.signInUserSession.idToken.payload['cognito:groups'][0]||'')
//console.log(user)

// }, [])

// function checkUser() {
//   Auth.currentAuthenticatedUser()
//     .then(user => console.log( user))//['username'] ))
//     .catch(err => console.log(err))
// }

// import Main from './Main.jsx'
// import Home from '../src/pages/home/Home.jsx'
/////////////////////////
// import Table from './components/Table.jsx';
// // import Header from './components/Header.jsx';
// //import Modal from "react-modal";
// //import {columnsCustomers, columnsClients, columnsJobbook} from './Columns.js';
// import GetTableData from './components/TableData.jsx';
// import GetTableColumns from './components/TableColumns.jsx'
// import AddNewClientScreen from './components/AddNewClientScreen.js';
// import ApplicationBar from './components/AppBar.jsx';

// import NavButtons from './components/NavButtons.jsx';
//  import Navbar from './components/Navbar.jsx';
//  import Sidebar from './components/Sidebar.jsx'

// const DATA = useRef([]);
// const [isOpen, setIsOpen] = useState(false);
// const [serverData, setServerData]= useState([{}]);
// //const [query, setQuery] = useState('SELECT * FROM customers');
// const [table, setTable] = useState({query : "SELECT * FROM clients",
//                                     tableName: 'clients', });

// useEffect(()=>{
//   loadDataFromServer(table.query, table.tableName);
// },[table])

// function toggleModal() {
//   setIsOpen(!isOpen);
// }

// const respData = (inp)=>{setServerData(inp)}
// async function loadDataFromServer(query, tableN) {
//     const response = await fetch(`http://localhost:3002/api?query=${query}`,
//         { method: 'POST',
//           headers: {'Content-Type': 'application/json; text/plain; */*; charset=utf-8' },
//           body: ''
//         });
//     const responseData = await response.json();
//     setServerData(responseData)
// };

//           {/* <div className='appContainer'>

//             <Navbar/>

//             </div>  */}
//              {/* <Sidebar/> */}
//             {/* <div className="appContainer">
//               <Navbar/>

//               {/* <AddNewClientScreen isOpen={isOpen} setIsOpen={setIsOpen} /> */}
//               {/* <Navbar/> */}
//             {/* ideba  <Sr/> */}
//               {/* <ApplicationBar /> */}
//               {/* <NavButtons toggleModal={toggleModal} setTable={setTable}/>
//               <Table columns= {GetTableColumns(table.tableName, {table} )} data={GetTableData( serverData, table.tableName)}/>
//               *}
//             </div>
//             {/* <AppRoutes /> */}
//              {/* <Main/>
//             </div> */}

//             {/* <Home/> */}

// /////////////
// {/* <SectionStyle></SectionStyle> */}

// <Header title={''} children={''}>
// {/* <Input className="something" placeholder="Search" backgroundColor="white" textColor="red" size=".2em"/>
// <PasswordInput className="something"  backgroundColor="white" size=".3em"/> */}
// {/* <Label><CheckInput className="something"  defaultChecked /><LabelText $mode="">Foo</LabelText></Label> */}
// </Header>       {/* &lt; 💅🏾 */}

//////////////IMPORTS

//import styled from 'styled-components'
//import { useTable } from 'react-table'
// import {
//           Title,
//           SectionStyle,
//           //..ButtonStyle,
//           TomatoButton,
//           //..Thing,
//           //..RotateDiv,
//           FadeInButton,
//           Spinner,
//           //...CheckInput,
//           //... Label,
//           //....LabelText,
//          } from './components/Styles.jsx'

// import * as dotenv from 'dotenv'
// dotenv.config({path: '../env'})
//require('dotenv').config()
// Modal.setAppElement("#root");

//  setLoggedInUser('')  //  setLoggedInGroup('')
// setLoggedInUser(payload.data.username )
// setLoggedInGroup(payload.data.signInUserSession.idToken.payload['cognito:groups'][0])
//console.log(payload)       //checkUser()

// ConfirmSignIn: {
//   Header() {
//     return <Heading>ログイン</Heading>
//   },
// },

// ResetPassword: {
//   Header() {
//     return <Text
//             color='darkred'
//             fontWeight={600}
//             fontSize={18}
//             fontStyle="normal"
//             textDecoration="none"
//           >
//             Reset your password
//           </Text> //<Heading>Reset your password</Heading> //パスワード再交付
//   },
// },

// <Heading
//   padding={tokens.space.xxxl}
//   width='100%'
//   //  height="50%"
//   color="green"
//   fontWeight="bold"
//   level={5}
//   //paddingTop ='45px'
//   //marginLeft = '30px'
//   //border='1px dotted red'
// >     // </Heading>
